const EthnicGroups = [
  'Arab',
  'Pakistani',
  'Bangladeshi',
  'English',
  'Irish',
  'Indian',

  'Chinese',

  'Caribbean',
  'African',
  'Mixed',
  'Any other',
]

export default EthnicGroups
