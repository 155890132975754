import styled from 'styled-components'
import { withRouter } from 'react-router'

import { PanelHeader } from '../Styled/Headers'
import { LikePanel } from '../Styled/ProfilePanel'
import { Grid, MainPagesWrap, PageWrapFullSizeContainer } from '../Styled/Wrap'
import { useSelector } from 'react-redux'
import { UserSelector } from '../Redux/UserSelector'
import { NotificationLikesSelector } from '../Redux/NotificationSelector'
import { Paragraph, H3 } from '../Styled/Typography'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import firebase, { deleteDoc, firestore, doc } from '../Firebase/Firebase.utils'
import { useEffect } from 'react'

const Likes = (props) => {
  const user = useSelector(UserSelector)
  const likesNotifications = useSelector(NotificationLikesSelector)
  const uid = user.uid
  const query1 = firestore.collection(`likes/${uid}/received`).limit(100)
  const query2 = firestore.collection(`likes/${uid}/send`).limit(100)
  const [likes] = useCollectionData(query1, {
    idField: 'uid',
  })
  const [liked] = useCollectionData(query2, {
    idField: 'uid',
  })

  const deleteNotifications = () => {
    if (!likesNotifications) return

    likesNotifications.map((notification) => {
      console.log('notification1', notification)
      const ref = firestore.collection('notification').doc(notification.uid)
      if (ref) {
        ref.delete()
      }
    })
  }

  useEffect(() => {
    console.log('11111111112222', likesNotifications)
    if (likesNotifications) {
      deleteNotifications()
    }
  }, [likesNotifications])

  if (!user) return null

  const likesYou = (likes, title) => {
    if (!likes || likes.length === 0) {
      return
    }

    return (
      <LikesContainer>
        <H3>{title}</H3>
        <Grid>
          {likes.map((like) => {
            return <LikePanel fullSize={likes.length < 3} dataItem={like} />
          })}
        </Grid>
      </LikesContainer>
    )
  }

  const empty = () => {
    if ((!likes || likes.length === 0) && (!liked || liked.length === 0)) {
      return <H3>No likes yet</H3>
    }
  }

  return (
    <PageWrapFullSizeContainer>
      <PanelHeader title="Likes" />

      <MainPagesWrap>
        {empty()}

        {likesYou(likes, 'Likes you')}
        {likesYou(liked, 'You liked')}

        {/* <Gap gap={15} /> */}
      </MainPagesWrap>
    </PageWrapFullSizeContainer>
  )
}

export default withRouter(Likes)

const LikesContainer = styled.div`
  padding-bottom: 35px;
`
