import React, { useState, useMemo } from 'react'
import './Modal.scss'
import styled from 'styled-components'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

const Modal = (props) => {
  const { visible, onCancel, animationClass = 'modal-slide' } = props
  const delayTime = 300
  const [completeVisible, setCompleteVisible] = useState(false)

  useMemo(() => {
    if (visible) {
      setCompleteVisible(visible)
    }
  }, [visible])

  function cancel() {
    if (onCancel) {
      onCancel()
    }

    setCompleteVisible(false)
  }

  if (!completeVisible) {
    return null
  }

  return (
    <CSSTransition
      in={visible}
      appear={true}
      timeout={delayTime}
      classNames={animationClass}
      onExited={() => cancel()}
    >
      <ModalContainer>
        <ModalWrapper>{props.children}</ModalWrapper>
        <ModalBackground className="background" onClick={cancel} />
      </ModalContainer>
    </CSSTransition>
  )
}

export const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 111;
  overflow-y: scroll;
`

const ModalBackground = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 99;
`

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  overflow-y: auto;
`

export default Modal
