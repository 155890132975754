import React from 'react'
import styled, { ThemeProvider } from 'styled-components'

const theme = {
  colors: {
    background: '#EEEEEE',
    backgroundEvent: '#FFF7B1',
    listHover: '#DDDDDD',
    landingBackground: '#222222',
    text: '#222222',
    link: '#1A73E8',
    textLight: '#888888',
    textDisabled: '#dddddd',
    primaryColor: '#CB323F',
    border: '#ddd',
    header: {
      background: '#fff',
    },
    footer: {
      background: '#fff',
    },
    button: {
      primary: {
        color: '#ffffff',
        background: '#1A73E8',
        hoverBackground: '#f00',
      },
      disabled: {
        color: '#222222',
        background: '#DDDDDD',
      },
    },
  },
  fontSize: '14px',
  fontLineHeight: '17px',
  fontFamily: 'Helvetica Neue, Roboto',
  input: {
    fontSize: '18px',
  },
}

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default Theme
