// const Education = [
//   'Doctorate',
//   'Masters',
//   'Bachelors',
//   'College',
//   'Secondary school',
//   'School',
//   'No education',
// ]

const Education = [
  { id: 7, title: 'Doctorate' },
  { id: 6, title: 'Masters' },
  { id: 5, title: 'Bachelors' },
  { id: 4, title: 'College' },
  { id: 3, title: 'Secondary school' },
  { id: 2, title: 'School' },
  { id: 1, title: 'No education' },
]

export default Education
