import React from 'react'
import styled from 'styled-components'
import { isMobile, isTablet } from 'react-device-detect'

interface HeadingProps {
  size?: number
  type?: string
  children?: any
  className?: string
  bottom?: number
}

export const Heading = (props: HeadingProps) => {
  const { size, type, children, className, bottom } = props
  const tag = `<${type} className={className}>${children}</${type}>`

  return (
    <HeadingContainer
      size={size}
      bottom={bottom}
      dangerouslySetInnerHTML={{ __html: tag }}
    ></HeadingContainer>
  )
}

const HeadingContainer = styled.div<HeadingProps>`
  ${({ bottom }) => bottom && `margin-bottom:${bottom}px`};

  h2 {
    font-size: 17px;
    line-height: 24px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${({ size }) =>
      size &&
      `
        font-size: ${size}px;
        line-height: ${size + 7}px;
      `}

    font-style: normal;
    font-weight: bold;
    color: ${(props) => props.theme.colors.text};
    margin: 0;
  }
`

export const H3 = styled.h3`
  font-size: 17px;
  line-height: 25px;
  margin-bottom: 15px;
`
