import styled from 'styled-components'

export const ListLabel = styled.ul`
  li {
    background: #fff;
    margin-bottom: 5px;
    padding: 7px 10px;

    label {
      margin-bottom: 0;
    }
  }
`
