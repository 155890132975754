import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Link,
} from 'react-router-dom'
import { withRouter } from 'react-router'

import styled from 'styled-components'
import { ReactComponent as IconHome } from '../../assets/svg/footer-home.svg'
import { ReactComponent as IconLikes } from '../../assets/svg/footer-likes.svg'
import { ReactComponent as IconMessages } from '../../assets/svg/footer-messages.svg'
import { ProfileImage } from '../ProfilePanel/ProfileImage'
import { useSelector } from 'react-redux'
import { UserSelector } from '../../Redux/UserSelector'
import {
  NotificationMessagesSelector,
  NotificationLikesSelector,
} from '../../Redux/NotificationSelector'

import { getTileInfo } from '../../Services/UserServices'

export const MainFooter = (props: any) => {
  const user = useSelector(UserSelector)
  const newMessages = useSelector(NotificationMessagesSelector)
  const newLikes = useSelector(NotificationLikesSelector)

  useEffect(() => {
    console.log('newLikes', newLikes)
  }, [newLikes])

  const isEnabled = () => {
    let loc = props.location
    const pages = ['/', '/landing', '/sign-in', '/sign-up', '/preferences']
    return pages.indexOf(loc.pathname) === -1
  }

  const getClassName = () => {
    return isEnabled() ? '' : 'disabled'
  }

  const getProfileImage = () => {
    if (!user) return

    const info = getTileInfo(user.profile)
    return info.image
  }

  return (
    <MainFooterContainer className={getClassName()}>
      <NavLink to={'/home'}>
        <IconHome />
      </NavLink>

      <NavLink to={'/likes'}>
        <WrapIcon>
          {newLikes && newLikes.length > 0 && <Badge />}
          <IconLikes />
        </WrapIcon>
      </NavLink>

      <NavLink to={'/messages'}>
        <WrapIcon>
          {newMessages && newMessages.length > 0 && <Badge />}
          <IconMessages />
        </WrapIcon>
      </NavLink>

      <NavLink to={'/profile'} className="circle-icon">
        <ProfileImage image={getProfileImage()} border={'#ccc'} size={'24px'} />
      </NavLink>
    </MainFooterContainer>
  )
}

const WrapIcon = styled.div`
  position: relative;
`

export const Badge = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background: ${(props) => props.theme.colors.button.primary.background};
  right: -6px;
  top: -6px;
`
const MainFooterContainer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  display: flex;
  height: 60px;
  background: ${(props) => props.theme.colors.footer.background};
  justify-content: space-around;
  align-items: center;

  &.disabled {
    height: 0;
    bottom: -62px;
    opacity: 0;
    overflow: hidden;
  }

  img {
    width: 24px;
  }

  a {
    -webkit-tap-highlight-color: transparent;
    &:visited,
    &:focus,
    &:active {
      outline: 0;
      border: none;
      -moz-outline-style: none;
    }

    &.active {
      svg {
        fill: ${(props) => props.theme.colors.text};
      }
    }
  }
`
