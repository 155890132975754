import React from 'react'
import styled from 'styled-components'
import backIcon from '../../assets/svg/back.svg'
import dotsIcon from '../../assets/svg/dots.svg'
import { isMobile, isTablet } from 'react-device-detect'
import { Heading } from '../Typography'

interface PanelHeaderProps {
  children?: any
  title?: string
  back?: any
  dotsToggle?: any
}

export const PanelHeader = (props: PanelHeaderProps) => {
  const { children, title, back, dotsToggle } = props
  return (
    <PanelHeaderContainer>
      <div className="flex-action">
        {back && (
          <div onClick={back}>
            <img src={backIcon} className="back" />
          </div>
        )}
      </div>

      <div className="flex-1">
        {title && (
          <Heading type={'h2'} size={17}>
            {title}
          </Heading>
        )}
      </div>

      <div className="flex-action">
        {dotsToggle && (
          <div onClick={dotsToggle}>
            <img src={dotsIcon} className="back" />
          </div>
        )}
      </div>
    </PanelHeaderContainer>
  )
}

const PanelHeaderContainer = styled.header<PanelHeaderProps>`
  position: sticky;
  top: 0;
  display: flex;
  height: 60px;
  background: ${(props) => props.theme.colors.header.background};
  justify-content: space-around;
  align-items: center;
  z-index: 2;

  .flex-action {
    min-width: 70px;
    text-align: center;
  }
  .flex-1 {
    flex: 1;
    text-align: center;
  }

  h2 {
    margin: 0;
  }

  .back {
    width: 30px !important;
  }
`
