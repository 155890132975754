let INITIAL_STATE = {
  message: '',
}

const NotificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'notification':
      return action.payload
    default:
      return state
  }
}

export const NotificationMessagesReducer = (state = [], action) => {
  console.log('11111111', state, action.payload)
  switch (action.type) {
    case 'notificationMessages':
      return action.payload ? action.payload : null
    default:
      return state
  }
}

export const NotificationLikesReducer = (state = [], action) => {
  switch (action.type) {
    case 'notificationLikes':
      return action.payload ? action.payload : null

    default:
      return state
  }
}

export default NotificationReducer
