import React from "react";
import styled from "styled-components";
import {isMobile, isTablet} from "react-device-detect";

interface GridProps {
  children?: any;
  className?: string;
}

export const Grid = (props: GridProps) => {
  return (
    <GridContainer className={props.className}>
      {props.children}
    </GridContainer>
  );
};

const GridContainer = styled.div<GridProps>`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
`;