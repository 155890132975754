import { createStore, applyMiddleware } from 'redux'

import logger from 'redux-logger'

import Reducer from './Reducer'

const middlewares = [logger]

const store = createStore(Reducer, applyMiddleware(...middlewares))
// const store = createStore(Reducer)

export default store
