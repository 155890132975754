import React, { useState } from 'react'

import { Button } from '../../Styled/Button'
import { NavLink } from 'react-router-dom'
import { Phone } from '../../Styled/Inputs'
import { PageWrap } from '../../Styled/Wrap'
import { Heading, Paragraph } from '../../Styled/Typography'
import firebase from '../../Firebase/Firebase.utils'
import { createUserProfile } from '../../Firebase/Firebase.CreateUserProfile'
import { SignInTemplate } from './SignIn'
import { Gap } from '../../Styled/Wrap/Gap'
// import { collection, doc, getDoc } from 'firebase/firestore'

function SignInPhone(props) {
  const { next, onPinEvent, onSetPhone, phone } = props

  const resend = () => {
    firebase.auth().signInWithPhoneNumber(phone, window.recaptchaData)
  }

  async function submit(event) {
    event.preventDefault()
    if (window.recaptchaData == undefined) {
      window.recaptchaData = new firebase.auth.RecaptchaVerifier('recaptcha', {
        size: 'invisible',
        defaultCountry: 'UK',
      })
    }

    // const docRef = doc(db, 'users', 'AdXHXFkuEPUqQPWNqoXA')
    // const subscriber = await firestore
    //   .collection('users')
    //   .doc('AdXHXFkuEPUqQPWNqoXA')
    //   .onSnapshot((documentSnapshot) => {
    //     createUserProfile(user)
    //     console.log('1111111111: ', documentSnapshot.data())
    //   })

    // const docSnap = await getDoc(docRef)
    // console.log('subscriber', subscriber)
    // if (docSnap.exists()) {
    //   console.log('11111111', docSnap)
    // }
    firebase
      .auth()
      .signInWithPhoneNumber(phone, window.recaptchaData)
      .then((e) => {
        e.resend = () => {
          return resend()
        }
        console.log('e', e)
        onPinEvent(e)
        next()
        return
        const code = prompt('Enter OTP', '')
        if (code == null) {
          return
        }

        e.confirm(code).then((result) => {
          createUserProfile(result.user)
        })
      })
  }

  const isValidForm = () => {
    return phone
  }

  return (
    <SignInTemplate title={`What is your phone number?`}>
      <Gap />
      <Phone onSet={onSetPhone}></Phone>
      <Gap />

      <Button disabled={!isValidForm()} onClick={submit}>
        Next
      </Button>
      {/* <Button onClick={signInWithGoogle}>Next</Button> */}

      <Paragraph className="text-details">
        We will send you a text with a verification code. Message or data rates
        may apply.
      </Paragraph>
    </SignInTemplate>
  )
}

export default SignInPhone
