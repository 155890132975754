import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as NoImage } from '../../assets/svg/no-photo.svg'
import Modal from '../../Pages/Modal/Modal'
import { getProfile } from '../../Services/UserServices'
import { withRouter } from 'react-router'

import Profile from '../../Pages/Profile'
import { UserSelector } from '../../Redux/UserSelector'
import { useSelector, connect } from 'react-redux'
import { Image } from '../../Styled/Icons/Image'
import { Heading, Paragraph } from '../../Styled/Typography'
interface ProfilePanelProps {
  lines?: number
  dataItem?: any
  history?: any
  dispatch?: any
}

const TableProfilePanel = (props: ProfilePanelProps) => {
  const currentUser = useSelector(UserSelector)
  const { dataItem, lines = 3, dispatch } = props

  const [user, setUser] = useState(false)
  const [profileVisible, setProfileVisible] = useState(false)

  const title = getProfile('title', dataItem)
  const subTitle = getProfile('occupation', dataItem)
  const status = getProfile('status', dataItem)
  const role = getProfile('role', dataItem)
  const shortDescription = getProfile('aboutMe', dataItem)
  const image = dataItem && dataItem.photos && dataItem.photos[0]

  const showUser = () => {
    setUser(dataItem)
    setProfileVisible(true)
  }

  const close = () => {
    setProfileVisible(false)
    setTimeout(() => {
      setUser(false)
    }, 400)
  }

  return (
    <>
      <Modal visible={profileVisible}>
        <Profile profile={user} restrictedView={true} onClose={close} />
      </Modal>

      <ProfilePanelContainer onClick={showUser}>
        <ProfileImageContainer>
          {image && <Image src={image} />}
          {!image && <NoImage />}
        </ProfileImageContainer>
        <Details>
          <div className="profile-title">
            <Heading type={'h2'} size={17}>
              {`${title} (${status}) (${role})`}
            </Heading>
            <Paragraph>{subTitle}</Paragraph>
          </div>
        </Details>
      </ProfilePanelContainer>
    </>
  )
}

export default connect()(withRouter(TableProfilePanel))

const ProfileImageContainer = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 100%;
  overflow: hidden;
  background: #dadada;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ProfilePanelContainer = styled.div`
  border: 1px solid #333;
  padding: 15px;
  display: flex;
  gap: 20px;

  &:hover {
    background: #fff;
    cursor: pointer;
  }

  .profile-title {
    margin-bottom: 10px;
  }

  h2 {
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 10px;
  }
`

const Details = styled.div`
  padding: 15px 0 0 0;
`
