import styled from 'styled-components'
import empty from '../../assets/svg/photo.svg'
import ComponentLoader from '../../Styled/Loader/loader'
import { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import { Gap } from '../Wrap/Gap'
import { ButtonPlain } from '../Button'
import { useUploadImages } from '../../Hooks/useUploadImages'

interface PhotosProps {
  children?: any
  images?: any
  photos?: any
  onHandleChange?: any
}

export const Photos = (props: PhotosProps) => {
  const { images, photos, onHandleChange } = props
  const useUpload = useUploadImages({ userImages: photos })
  const userPhotos = useUpload.getImages()

  const handleChange = (event) => {
    if (event.target.files) {
      const files = Object.values(event.target.files)
      useUpload.upload(files)
    }
  }

  const onRemove = (file) => {
    return useUpload.remove(file)
  }

  useEffect(() => {
    setUrls(userPhotos)
  }, [userPhotos])

  const setUrls = (userPhotos: any) => {
    if (userPhotos && userPhotos.length > 0) {
      const imgs: string[] = []
      userPhotos.map((photo) => {
        if (photo && photo.webUrl) {
          imgs.push(photo.webUrl)
        }
      })
      if (imgs && imgs.length > 0) {
        onHandleChange('photos', imgs)
      }
    }
  }
  return (
    <PhotosContainer>
      <PhotosPanel>
        {userPhotos &&
          userPhotos.length > 0 &&
          userPhotos.map((image) => (
            <ListContainer>
              <StyledDeleteIcon
                fontSize="large"
                onClick={() => onRemove(image)}
              />
              <ComponentLoader visible={!image.webUrl} position={'absolute'} />
              {image && image.webUrl && <Image src={image.webUrl} />}
            </ListContainer>
          ))}

        {(userPhotos == undefined || userPhotos.length < 1) && (
          <>
            <ListEmptyContainer />
            <ListEmptyContainer />
            <ListEmptyContainer />
            <ListEmptyContainer />
          </>
        )}
      </PhotosPanel>

      <ButtonPlain>
        <BrowseFiles
          accept="image/*"
          className="btn-plain"
          type="file"
          onChange={handleChange}
          multiple
        />
        Browse more photos
      </ButtonPlain>
    </PhotosContainer>
  )
}

const PhotosContainer = styled.ul<PhotosProps>`
  margin-bottom: 20px;
`
const PhotosPanel = styled.ul<PhotosProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 10px;
`

const ListContainer = styled.li`
  background: #c4c4c4;
  height: 160px;
  cursor: pointer;
  position: relative;
`

const ListEmptyContainer = styled(ListContainer)`
  background-image: url(${empty});
  background-position: center;
  background-repeat: no-repeat;
`

const Image = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: cover;
`

const StyledDeleteIcon = styled(DeleteIcon)`
  position: absolute;
  font-size: 50px;
  color: #000;
  top: 5px;
  right: 5px;
  cursor: pointer;
`

const BrowseFiles = styled.input`
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
`
