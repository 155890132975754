import React from 'react'
import styled from 'styled-components'
import { isMobile, isTablet } from 'react-device-detect'

interface PageWrapProps {
  children?: any
  isMobile?: boolean
  className?: any
  style?: any
  padding?: number
}

export const PageWrap = (props: PageWrapProps) => {
  return (
    <PageWrapContainer
      isMobile={isMobile}
      className={props.className}
      style={props.style}
      padding={props.padding}
    >
      {props.children}
    </PageWrapContainer>
  )
}

const PageWrapContainer = styled.div<PageWrapProps>`
  padding: 0 30px 0;
  text-align: left;

  ${({ isMobile }) =>
    isMobile &&
    `
    
  `}

  ${({ padding }) =>
    padding &&
    `padding: ` +
      padding +
      `px;
  `}

  &.wrap-secondary {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`

export const MainPagesWrap = styled.div`
  padding: 20px 20px 60px 20px;
  // overflow: scroll;
`

export const MainPagesSpace = styled.div`
  padding: 20px 20px;
`

export const PageWrapFullSize = (props: PageWrapProps) => {
  return <PageWrapFullSizeContainer>{props.children}</PageWrapFullSizeContainer>
}

export const PageWrapFullSizeContainer = styled.div`
  position: absolute;
  min-height: 100%;
  left: 0;
  right: 0;
  top: 0;
`

export const StickyBottom = styled.div<{ background?: string }>`
  position: sticky;
  bottom: 0;
  padding: 10px 20px 10px 20px;
  background: ${(props) => props.background};
`
