import React, { useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import styled from 'styled-components'

const SliderItemContainer = styled.div``

const SlideImage = styled.img`
  object-fit: cover;
  height: 50vh;
`

export const Slider = (props) => {
  const [slide, setSlide] = useState(0)
  const [images, setImages] = useState(props.images)

  return (
    <Carousel
      swipeable={true}
      emulateTouch={false}
      showArrows={true}
      showThumbs={false}
      autoPlay={false}
      centerMode={false}
      dynamicHeight={true}
      infiniteLoop={false}
      showStatus={false}
      interval={1}
    >
      {images &&
        images.map((image, index) => (
          <SliderItemContainer key={index}>
            <SlideImage src={image.url} />
          </SliderItemContainer>
        ))}
    </Carousel>
  )
}

export default Slider
