import styled from 'styled-components'
import { firestore } from '../Firebase/Firebase.utils'
import { useEffect, useState } from 'react'

import { ReactComponent as IconMessages } from '../assets/svg/footer-messages.svg'
import { ReactComponent as IconLiked } from '../assets/svg/liked.svg'

import { Paragraph } from '../Styled/Typography'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { connect, useSelector } from 'react-redux'
import { NotificationSelector } from '../Redux/NotificationSelector'
import { withRouter } from 'react-router-dom'

const Notification = (props) => {
  const { user, dispatch } = props
  const [visible, setVisible] = useState(false)
  const [icon, setIcon] = useState<string | boolean>(false)
  const [delay, setDelay] = useState(false)
  const [message, setMessage] = useState<string>('')
  const customNotifications = useSelector(NotificationSelector)

  const uid = user.uid
  const query = firestore
    .collection('notification')
    .where('status', '==', 'active')
    .where('userId', '==', uid)
  // .orderBy('created')
  // .limit(1)

  const queryMessages = firestore
    .collection('notification')
    .where('type', '==', 'message')
    .where('userId', '==', uid)

  const queryLikes = firestore
    .collection('notification')
    .where('type', '==', 'like')
    .where('userId', '==', uid)

  const [notifications] = useCollectionData(query, {
    idField: 'uid',
  })

  const [notificationsMessages] = useCollectionData(queryMessages, {
    idField: 'uid',
  })

  const [notificationsLikes] = useCollectionData(queryLikes, {
    idField: 'uid',
  })

  const constructMessage = () => {
    if (!notifications || notifications.length < 1) return
    setDelay(true)
    setIcon(false)

    const messages = []
    const likes = []
    const combined = []
    const filtered = notifications.filter((notification) => {
      return notification.status == 'active'
    })

    filtered.map((notification) => {
      if (notification.type == 'message') {
        messages.push(notification.msg)
      }
      if (notification.type == 'like') {
        likes.push(notification.msg)
      }
      combined.push(notification.msg)
    })

    let text = ''
    if (combined.length < 1) {
      text = ''
    } else if (combined.length === 1) {
      text = combined[0]
      setIcon(messages.length > 0 ? 'message' : 'like')
    } else if (combined.length > 1) {
      text = `You've got `
      let and = ''
      if (messages.length > 0) {
        text = `${text} ${messages.length} new message${
          messages.length > 1 ? 's' : ''
        }`
        and = ' and'
      }
      if (likes.length > 0) {
        text = `${text}${and} ${likes.length} new like${
          likes.length > 1 ? 's' : ''
        }`
      }
    }

    initMessage(text)
    cancelNotification(notifications)
  }

  const initMessage = (text) => {
    setMessage(text)
    setVisible(!!text)
    if (text) {
      setTimeout(() => {
        setDelay(false)
        setVisible(false)
      }, 5000)
    }
  }

  useEffect(() => {
    if (delay) return
    constructMessage()
  }, [notifications])

  useEffect(() => {
    if (!customNotifications) return
    setMessage(customNotifications.message)
    initMessage(customNotifications.message)
    console.log('customNotifications', customNotifications)
    setIcon(customNotifications.type) // 'message' : 'like'
  }, [customNotifications])

  useEffect(() => {
    dispatch({
      type: 'notificationMessages',
      payload: notificationsMessages,
    })
  }, [notificationsMessages])

  useEffect(() => {
    dispatch({
      type: 'notificationLikes',
      payload: notificationsLikes,
    })
  }, [notificationsLikes])

  const cancelNotification = (notifications) => {
    if (!notifications) return

    notifications.map((notification) => {
      firestore.collection('notification').doc(notification.uid).update({
        status: 'inactive',
      })
    })
  }

  if (!notifications || !user) return null

  return (
    <NotificationContainer visible={visible}>
      {message && (
        <MessageContainer>
          {icon && (
            <IconContainer>
              {icon == 'message' && <IconMessages style={{ fill: '#222' }} />}
              {icon == 'like' && <IconLiked />}
            </IconContainer>
          )}
          <StyledParagraph>{message}</StyledParagraph>
        </MessageContainer>
      )}
    </NotificationContainer>
  )
}

export default connect()(withRouter(Notification))

export const NotificationContainer = styled.div<{ visible?: boolean }>`
  position: fixed;
  border-radius: 10px;
  top: -100px;
  left: 20px;
  right: 20px;
  background: #000;
  z-index: 100;
  transition: all 0.5s ease-out;

  ${({ visible }) => visible == true && `top: 75px;`}
`

const StyledParagraph = styled(Paragraph)`
  color: #fff;
`

const MessageContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 10px;
`

const IconContainer = styled.div`
  background: #fff;
  height: 44px;
  min-width: 44px;
  max-width: 44px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
