import styled from 'styled-components'
import { Button } from '../Button'
import { ProfileImage } from '../ProfilePanel/ProfileImage'
import { Paragraph } from '../Typography'
import { useEffect, useState, useRef, useMemo } from 'react'
import { firestore } from '../../Firebase/Firebase.utils'
import { loadProfile } from '../../Firebase/Firebase.LoadProfiles'
import { createNotification } from '../../Firebase/Firebase.Notification'

import { getMessage } from '../../Firebase/Firebase.Message'

import { createMessage } from '../../Firebase/Firebase.Message'

import { getTileInfo, getAgo } from '../../Services/UserServices'
import { Input } from '../Inputs/Input'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { LikeAccept } from '../../Components/LikeAccept'
import { NotificationMessagesSelector } from '../../Redux/NotificationSelector'
import { useSelector } from 'react-redux'

export const MessagePanel = (props) => {
  const { user, onTitleUpdate, roomId } = props
  const [myImage, setMyImage] = useState()
  const [uidOther, setUIDOther] = useState()
  const [senderImage, setSenderImage] = useState()
  const [otherUser, setOtherUser] = useState()
  const [msg, setMsg] = useState<string | undefined>()

  const [status, setStatus] = useState()

  const [scrollMessages, setScrollMessages] = useState()
  const [loading, setLoading] = useState(true)
  const newMessages = useSelector(NotificationMessagesSelector)
  // deleteCollection(`/chatrooms/${roomId}/messages`)

  // useEffect(() => {
  //   const unsub = onSnapshot(doc(firestore, 'chatrooms', roomId), (doc) => {
  //     const data = doc.data()
  //     if (data) {
  //       setStatus(data.status)
  //     }
  //   })
  // }, [])

  const scrollToRef = useRef<any>()
  const scrollRef = useRef()
  const scrollToMessage = () => {
    scrollToRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (newMessages) {
      newMessages.map((messages) => {
        if (messages.roomId) {
          firestore.collection('notification').doc(messages.uid).delete()
        }
      })
    }
  }, [newMessages])

  useEffect(() => {
    firestore
      .doc(`chatrooms/${roomId}`)
      .get()
      .then((queryResult) => {
        const data = queryResult.data()
        if (data) {
          setUIDOther(
            user.uid == data.ownerId.id ? data.receiverId.id : data.ownerId.id
          )
          setStatus(data.status)
        }
      })
  }, [])

  const query = firestore
    .collection(`chatrooms/${roomId}/messages`)
    .orderBy('created', 'desc')
  // .where('chatId', 'in', [
  //   `${user.uid}_${uidOther}`,
  //   `${uidOther}_${user.uid}`,
  // ])

  const loadMessages = (query, limit) => {
    let tmpQuery = query
    if (limit) {
      tmpQuery = tmpQuery.limit(limit)
    }

    return tmpQuery
  }

  let [messages] = useCollectionData(loadMessages(query, 10), {
    idField: 'uid',
  })

  useEffect(() => {
    if (user && user.uid && uidOther) {
      const { image } = getTileInfo(user.profile)
      if (image) {
        setMyImage(image)
      }

      loadProfile(uidOther).then((otherUser) => {
        setOtherUser(otherUser)
        const { image, title } = getTileInfo(otherUser)
        setSenderImage(image)
        if (onTitleUpdate) onTitleUpdate(title)
      })
    }
  }, [user, uidOther])

  const myImageProfile = useMemo(() => {
    return <ProfileImage image={myImage} />
  }, [myImage])
  const senderImageProfile = useMemo(
    () => <ProfileImage image={senderImage} />,
    [senderImage]
  )

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const send = async (e) => {
    e.preventDefault()

    if (!otherUser) return
    if (!msg) return

    const data = await createMessage(roomId, user.uid, uidOther, msg)
    const message = `You've received a new message from ${otherUser?.name}`
    await createNotification(uidOther, message, 'message', {
      roomId: data?.roomId,
    })

    setMsg('')
    scrollToMessage()
  }

  const loadMore = (event) => {
    if (loading === true) return false

    console.log('loading', loading)

    if (scrollRef.current.scrollTop < 30) {
      console.log('scrollRef.current.scrollTop', scrollRef.current.scrollTop)
      getMessage(user.uid, uidOther, 100).then((message) => {
        if (message.length > 0) {
          setScrollMessages(message)
        }
      })
    }

    return true
  }

  const scrollToBottom = () => {
    const messagesContainer = scrollRef.current
    messagesContainer.scroll(0, messagesContainer.scrollHeight)
    setLoading(false)
  }

  const messageItems = (messages) => {
    if (!messages) return

    messages = messages.sort((a, b) => {
      return a.created - b.created
    })

    if (!myImageProfile || !senderImageProfile) return

    return messages.map((message, i) => {
      const { msg, created, senderId, receiverId } = message
      const type = user.uid === senderId.id ? 'me' : 'sender'
      const time = getAgo(created?.seconds)

      return (
        <MessageItem type={type} key={msg.id}>
          {type === 'me' && myImageProfile}
          {type === 'sender' && senderImageProfile}
          <div className="message-box">
            <Paragraph>{msg}</Paragraph>
            <time>{time}</time>
          </div>
        </MessageItem>
      )
    })
  }

  return (
    <MessagePanelContainer>
      <MessagesBox ref={scrollRef} onScroll={loadMore}>
        {/* {messageItems(scrollMessages)} */}
        {messages && messageItems(messages)}
        <div ref={scrollToRef}></div>
      </MessagesBox>

      <LikeAccept
        canMessage={false}
        roomId={roomId}
        updateStatus={setStatus}
        profile={otherUser}
      />

      {status === 'active' && (
        <form onSubmit={send}>
          <div className="message-input">
            <Input
              type="text"
              placeholder={'say something nice ...'}
              onChange={(event) => setMsg(event.target.value)}
              value={msg}
            />

            <Button type={'submit'} width={120} onClick={send} disabled={!msg}>
              Send
            </Button>
          </div>
        </form>
      )}
    </MessagePanelContainer>
  )
}

const MessagePanelContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background: #fff;

  .message-input {
    display: flex;
    height: 60px;
    gap: 10px;
    padding: 10px;
    border-top: 1px solid #efefef;

    textarea {
      flex: 1;
      height: 100%;
      resize: none;
      outline: none;
      background: #fff;
      borer-radius: 5px;
      border: none;
      overflow: hidden;
      box-sizing: border-box;
    }
  }
`

//(InfiniteScroll)`
const MessagesBox = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding: 20px 10px 0 10px;
  height: 100%;
  background: ${(props) => props.theme.colors.background};
`

const MessageItem = styled.div<{ type?: string | undefined }>`
  min-width: 100%;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  ${({ type }) => type == 'me' && `flex-direction: row-reverse;`}

  &:last-child {
    margin-bottom: 0;
  }

  .message-box {
    position: relative;
    background: #fff;
    padding: 20px;
    flex: 1;

    p {
      max-width: 95%;
    }
  }

  time {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
`
