import { firestore } from './Firebase.utils'

export const createUserProfile = async (userAuth, options) => {
  if (!userAuth) return
  const userRef = firestore.doc(`users/${userAuth.uid}`)
  const snapShot = await userRef.get()

  if (!snapShot.exists) {
    const { displayName, email, phoneNumber, uid } = userAuth
    console.log('1111122userAuth', options)
    const created = new Date() //firebase.firestore.FieldValue.serverTimestamp(),
    try {
      userRef.set({
        phoneNumber,
        displayName,
        email,
        created,
        ...options,
      })
    } catch (error) {
      console.log('1111122', error.message)
    }
  }

  if (snapShot.exists) {
    return await snapShot.data()
  }

  return userRef
}

export const updateUserProfile = async (userAuth, options) => {
  if (!userAuth) return

  const userRef = firestore.doc(`users/${userAuth.uid}`)
  const snapShot = await userRef.get()
  const updated = new Date()

  if (!snapShot.exists) return

  // console.log('1111111', userAuth, snapShot.exists, { ...options })
  // userAuth.updateProfile({
  //   updated: updated,
  //   displayName: 'ehson111',
  //   test: 1111,
  // })
  // return

  if (options.education) {
    options.education = parseInt(options.education)
  }
  if (options.height) {
    options.height = parseFloat(options.education)
  }
  if (options.practising) {
    options.practising = parseInt(options.practising)
  }

  const tags = createTags(options)
  options.tags = tags

  try {
    var promise = new Promise(function (resolve, reject) {
      userRef.update({
        updated,
        ...options,
      })

      userRef.get().then((user) => {
        resolve(user.data())
      })
    })
  } catch (error) {
    console.log('11111', error.message)
  }

  return promise
  return userRef
}

export const createTags = (options) => {
  let tags = []
  if (options.ethnicity) {
    tags.push(`eth_${options.ethnicity}`)
  }

  if (options.languages) {
    tags = tags.concat(options.languages)
    options.languages.forEach((lang) => {
      tags.push(`eth_${options.ethnicity}_${lang}`)
    })
  }

  return tags
}
