// export const config = {
//   apiKey: 'AIzaSyAkHtqbHwRnCgV9u0vIRHanBQQG5x3EwAk',
//   authDomain: 'salammatchapp.firebaseapp.com',
//   projectId: 'salammatchapp',
//   storageBucket: 'salammatchapp.appspot.com',
//   messagingSenderId: '62084055908',
//   appId: '1:62084055908:web:0d2717fdf9f010acbc7071',
//   measurementId: 'G-TJBHH1YMS3',
// }

// var encoded = btoa(JSON.stringify(config1))
// console.log(111111111, encoded)

export const config =
  'eyJhcGlLZXkiOiJBSXphU3lBa0h0cWJId1JuQ2dWOXUwdklSSGFuQlFRRzV4M0V3QWsiLCJhdXRoRG9tYWluIjoic2FsYW1tYXRjaGFwcC5maXJlYmFzZWFwcC5jb20iLCJwcm9qZWN0SWQiOiJzYWxhbW1hdGNoYXBwIiwic3RvcmFnZUJ1Y2tldCI6InNhbGFtbWF0Y2hhcHAuYXBwc3BvdC5jb20iLCJtZXNzYWdpbmdTZW5kZXJJZCI6IjYyMDg0MDU1OTA4IiwiYXBwSWQiOiIxOjYyMDg0MDU1OTA4OndlYjowZDI3MTdmZGY5ZjAxMGFjYmM3MDcxIiwibWVhc3VyZW1lbnRJZCI6IkctVEpCSEgxWU1TMyJ9'
