import styled from 'styled-components'
import { ProfileImage } from '../ProfilePanel/ProfileImage'
import { Heading, Paragraph } from '../Typography'
import { withRouter } from 'react-router'
import { useEffect, useState } from 'react'
import { loadProfile } from '../../Firebase/Firebase.LoadProfiles'
import { getTileInfo, getAgo } from '../../Services/UserServices'
import { Badge } from '../Headers/MainFooter'

const MessagePreviewPanel = (props: any) => {
  const { dataItem, uid, onShowMessage, unred } = props
  const [profile, setProfile] = useState()
  const { receiverId, ownerId, roomId, lastMessage, updated } = dataItem

  let profileId = undefined
  useEffect(() => {
    if (dataItem) {
      if (receiverId.id === uid) {
        profileId = ownerId.id
      } else {
        profileId = receiverId.id
      }
      if (profileId) {
        loadProfile(profileId).then((profile) => {
          setProfile(profile)
        })
      }
    }
  }, [dataItem])

  if (!dataItem || !profile) {
    return null
  }

  const { image, title } = getTileInfo(profile)
  const time = getAgo(updated?.seconds || updated)

  const showMessage = () => {
    onShowMessage()
    // props.history.push(`/message/${roomId}`)
  }

  return (
    <MessageShortPanelContainer onClick={showMessage} unred={unred}>
      {unred && <UnRedPin />}
      {image && <ProfileImage image={image} />}
      <div className="message-details">
        <time>{time}</time>
        <Heading type={'h2'} size={17} className={'text-ellipsis'}>
          {title}
        </Heading>
        <Paragraph>{lastMessage}</Paragraph>
      </div>
    </MessageShortPanelContainer>
  )
}

export default withRouter(MessagePreviewPanel)

const MessageShortPanelContainer = styled.div<{ unred?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  position: relative;

  ${({ unred, theme }) =>
    unred == true && `background: ${theme.colors.backgroundEvent};`}

  h2 {
    width: 60%;
  }

  time {
    position: absolute;
    right: 20px;
  }

  .message-details {
    flex: 1;
  }
`

const UnRedPin = styled(Badge)`
  right: 10px;
  top: 37px;
`
