import { useState } from 'react'
import { connect } from 'react-redux'

import { withRouter } from 'react-router'
import { Button, ButtonLink } from '../../Styled/Button'
import { Pin } from '../../Styled/Inputs'
import { Paragraph } from '../../Styled/Typography'
import { createUserProfile } from '../../Firebase/Firebase.CreateUserProfile'
import { SignInTemplate } from './SignIn'
import { Gap } from '../../Styled/Wrap/Gap'

const SignInPin = (props) => {
  const { pinEvent, next, back, phone } = props
  const [pin, setPin] = useState('')

  const submit = () => {
    if (!pinEvent || !pin) {
      return
    }

    try {
      pinEvent.confirm(pin).then(
        (result) => {
          createUserProfile(result.user)
          return next()
        },
        (error) => {
          console.log('error', error)
        }
      )
    } catch (e) {}
  }

  const resend = () => {
    return pinEvent.resend()
  }

  const isValidForm = () => {
    return pin
  }

  return (
    <SignInTemplate title={`My code is`}>
      <Paragraph className="text-details" isInnerHTML="true">
        Sent code to {phone}.{' '}
        <ButtonLink onClick={back}>Edit number</ButtonLink>
      </Paragraph>
      <Gap />
      <Pin onSet={setPin} length={6}></Pin>
      <Gap />

      <Button disabled={!isValidForm()} onClick={submit}>
        Next
      </Button>
      {/* <Button onClick={signInWithGoogle}>Next</Button> */}

      <Paragraph className="text-details" isInnerHTML="true">
        Didn’t receive it?{' '}
        <ButtonLink onClick={resend}>Send it again.</ButtonLink>
      </Paragraph>
    </SignInTemplate>
  )
}

export default connect()(withRouter(SignInPin))
