let INITIAL_STATE = {
  user: false,
}

const AuthReducer = (state = INITIAL_STATE, action) => {
  console.log('action.payload', action.payload)
  switch (action.type) {
    case 'login':
      return {
        ...state,
        user: action.payload,
      }

    default:
      return state
  }
}

export default AuthReducer
