import { useState } from 'react'

import { CustomSlider } from '../../Styled/Inputs/Slider'
import { Label } from '../../Styled/List/List'
import { Gap } from '../../Styled/Wrap/Gap'
import { Button } from '../../Styled/Button/Button'
import { getPreferenceOptions, getFormatted } from '../../Services/UserServices'
import { PageWrap } from '../../Styled/Wrap'

const RangeType = (props) => {
  const { options, submit } = props
  const { defaultValue, id } = options

  const pageOptions = getPreferenceOptions(id)
  const { title } = pageOptions

  const [value, setValue] = useState(
    defaultValue ? defaultValue : pageOptions['defaultValue']
  )

  const isRange = value.length > 0

  const handleChange = (newValue) => {
    setValue(newValue)
  }

  return (
    <PageWrap padding={20}>
      <Label className="reset">
        <span>{title}</span>
        <span>
          <strong>
            {value && isRange && value[0] && getFormatted(value[0], id)}
            {value && isRange && value[1] && ' - ' + getFormatted(value[1], id)}

            {value && !isRange && getFormatted(value, id)}
          </strong>
        </span>
      </Label>

      <CustomSlider
        valueLabelDisplay={'auto'}
        value={value}
        onChange={handleChange}
        // aria-labelledby="range-slider"
        {...pageOptions}
      />
      <Gap />
      <Button onClick={() => submit(value)}>Done</Button>
    </PageWrap>
  )
}

export default RangeType
