import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PanelHeader } from '../Styled/Headers'
import { PageWrapFullSizeContainer } from '../Styled/Wrap'

import { Button } from '../Styled/Button'
import { Paragraph } from '../Styled/Typography'
import { withRouter } from 'react-router'
import { Photos } from '../Styled/Photos'
import { Textarea } from '../Styled/Inputs/Textarea'
import Preferences from './Preferences/Preferences'
import { connect } from 'react-redux'
import { getProfile, getListItems } from '../Services/UserServices'
import { updateUserProfile } from '../Firebase/Firebase.CreateUserProfile'
import { Gap } from '../Styled/Wrap/Gap'

const ProfileEdit = (props) => {
  const { createUser, user } = props
  const [data, setData] = useState({})

  useEffect(() => {
    if (user.profile) {
      getListItems(true).map((item) => {
        if (profile[item]) {
          data[item] = profile[item]
        }
        setData(data)
      })
    }
  }, [user.profile])

  if (!user) return null
  const { profile } = user
  const { photos } = profile

  const back = () => {
    props.history.push('/profile')
  }

  const handleChange = (event) => {
    const { id, name, value, innerHTML } = event.target
    const tmpName = id ? id : name
    const tmpValue = id ? innerHTML : value
    updateState(tmpName, tmpValue)
  }

  const updateState = (id, value) => {
    data[id] = value
    setData(data)
  }

  const onSubmit = () => {
    updateUserProfile(user, data).then((userData) => {
      if (user && userData) {
        user.profile = userData
        props.dispatch({ type: 'login', payload: user })
      }
      back()
    })
  }

  return (
    <PageWrapFullSizeContainer>
      <PanelHeader title="Update Profile" back={() => back()} />

      <PageWrap>
        <PageSpace>
          <Paragraph size={14}>Photos</Paragraph>
          <Gap gap={10} />
          <Photos photos={photos} onHandleChange={updateState} />

          <Gap gap={10} />
          <Paragraph size={14}>About me</Paragraph>
          <Gap gap={10} />
          <Textarea
            name="aboutMe"
            placeholder={'Enter about me'}
            onChange={handleChange}
          >
            {getProfile('aboutMe', profile)}
          </Textarea>

          <Gap gap={10} />
          <Paragraph size={14}>About my family</Paragraph>
          <Gap gap={10} />
          <Textarea
            name="aboutFamily"
            placeholder={'About my family'}
            onChange={handleChange}
          >
            {getProfile('aboutFamily', profile)}
          </Textarea>

          <Gap gap={10} />
          <Paragraph size={14}>Things I like</Paragraph>
          <Gap gap={10} />
          <Textarea
            name="hobby"
            placeholder={'Things I like'}
            onChange={handleChange}
          >
            {getProfile('hobby', profile)}
          </Textarea>

          <Gap gap={10} />
          <Paragraph size={14}>Looking for someone</Paragraph>
          <Gap gap={10} />
          <Textarea
            name="looking"
            placeholder={'Looking for someone'}
            onChange={handleChange}
          >
            {getProfile('looking', profile)}
          </Textarea>
        </PageSpace>

        <Gap gap={15} />
        <Preferences
          onHandleChange={updateState}
          onProfileUpdate={updateState}
          isPlain={true}
          isProfile={true}
          profile={data}
        />
        <PageSpace>
          <Button onClick={onSubmit}>Done</Button>
        </PageSpace>
      </PageWrap>
    </PageWrapFullSizeContainer>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  createUser: state.createUser.createUser,
})
export default connect(mapStateToProps)(withRouter(ProfileEdit))

const PageWrap = styled.div`
  padding: 20px 0 80px 0;
  // overflow: scroll;
`

const PageSpace = styled.div`
  padding: 0 20px;
`
