import { useState } from 'react'
import styled from 'styled-components'

import PreferencesList from './List'
import Modal from '../Modal/Modal'
import { connect } from 'react-redux'
import { updateUserProfile } from '../../Firebase/Firebase.CreateUserProfile'
import { withRouter } from 'react-router'
import { PanelHeader } from '../../Styled/Headers'

import RangeType from './RangeType'
import SelectableType from './SelectableType'
import InputType from './InputType'
import Autocomplete from './Autocomplete'
import LocationType from './LocationType'
import { getListItems } from '../../Services/UserServices'
import Occupations from '../../Data/Occupations'
import Languages from '../../Data/Languages'

const Preferences = (props) => {
  const {
    isPlain,
    isProfile,

    onProfileUpdate,
    preferences,
    user,
    preferencesVisible,
    dispatch,
    onClose,
    profile,
  } = props

  const dataSource = isProfile ? profile : preferences

  const [visible, setVisible] = useState(false)
  const [component, setComponent] = useState(false)

  const goTo = (item) => {
    console.log('item', item)
    if (!item) {
      setVisible(false)
      // setComponent(false)
    }

    if (item.id) {
      setVisible(true)
      setComponent(item)
    }
  }

  const save = (reset?: boolean) => {
    updateUserProfile(user, {
      preferences: reset === true ? {} : preferences,
    }).then((userData) => {
      if (userData.preferences) {
        props.dispatch({
          type: 'userPreferences',
          payload: userData.preferences,
        })
      }

      if (preferencesVisible) {
        onClose(true)
      } else {
        props.history.push('/home')
      }
    }, props)
  }

  const reset = () => {
    save(true)
  }

  const submitModal = (value) => {
    if (!component) return

    if (isProfile) {
      onProfileUpdate(component.id, value)
    } else {
      dispatch({
        type: 'userPreference',
        node: component.id,
        payload: value,
      })
    }
    goTo(0)
  }

  const cancel = () => {
    setComponent(false)
  }

  const availableItems = getListItems(isProfile)

  const getOptions = (id) => {
    if (!dataSource) return null

    // Occupations

    let items = []
    let isMultiple: boolean = false
    let defaultValue = dataSource[id]
    if (id == 'occupation' && Occupations.length > 0) {
      items = items.concat(Occupations)
      isMultiple = false
    } else if (id == 'languages' && Languages.length > 0) {
      items = items.concat(Languages)
      isMultiple = true
    }

    const options = {
      id: id,
      defaultValue: defaultValue,
      isProfile: isProfile,
      items: items,
      isMultiple: isMultiple,
    }

    return options
  }

  const modals = {
    name: <InputType submit={submitModal} options={getOptions('name')} />,
    occupation: (
      <Autocomplete submit={submitModal} options={getOptions('occupation')} />
    ),
    dob: <InputType submit={submitModal} options={getOptions('dob')} />,
    location: (
      <LocationType
        submit={submitModal}
        defaultValue={getOptions('location')}
      />
    ),
    age: <RangeType submit={submitModal} options={getOptions('age')} />,
    height: <RangeType submit={submitModal} options={getOptions('height')} />,
    distance: (
      <RangeType submit={submitModal} options={getOptions('distance')} />
    ),
    ethnicity: (
      <SelectableType submit={submitModal} options={getOptions('ethnicity')} />
    ),
    practising: (
      <SelectableType submit={submitModal} options={getOptions('practising')} />
    ),
    languages: (
      // <Autocomplete submit={submitModal} options={getOptions('languages')} />
      <SelectableType submit={submitModal} options={getOptions('languages')} />
    ),
    education: (
      <SelectableType submit={submitModal} options={getOptions('education')} />
    ),
    group: (
      <SelectableType submit={submitModal} options={getOptions('group')} />
    ),
  }
  return (
    <PreferencesContainer>
      <Modal visible={visible} animation={'slide'} onCancel={cancel}>
        <PreferencesInnerContainer>
          <PanelHeader title={component.title} back={() => goTo(0)} />

          {/* <PageWrap padding={20}> */}
          {modals[component.id]}
          {/* </PageWrap> */}
        </PreferencesInnerContainer>
      </Modal>

      <PreferencesList
        dataSource={dataSource}
        goTo={goTo}
        isPlain={isPlain}
        onSubmit={save}
        onReset={reset}
        availableItems={availableItems}
      />
    </PreferencesContainer>
  )
}

const mapStateToProps = (state) => ({
  preferences: state.preferences.preferences,
  user: state.auth.user,
  preferencesVisible: state.preferences.preferencesVisible,
})
export default connect(mapStateToProps)(withRouter(Preferences))

export const PreferencesTemplate = (props) => {
  const { children, title } = props
  return (
    <PreferencesContainer>
      <PanelHeader title={title} back={() => props.goTo(0)} />

      {children}
    </PreferencesContainer>
  )
}

const PreferencesContainer = styled.div`
  min-height 100%;
  height 100%;
  width: 100%;  
`

export const PreferencesInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.colors.background};
  overflow-y: auto;

  .scrollable {
    flex: 1;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
`
