import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/storage'

import { config } from './config'

const con = JSON.parse(atob(config))
firebase.initializeApp(con)

export const auth = firebase.auth()
export const firestore = firebase.firestore()
// export collection auth = firebase.auth()

const provider = new firebase.auth.GoogleAuthProvider()
provider.setCustomParameters({ promt: 'select_account' })
const storage = firebase.storage()
export { storage, firebase as default }

export const signInWithGoogle = () => auth.signInWithPopup(provider)

export const getReferenceData = (refData) => {
  if (!refData) return null
  var promise = new Promise(function (resolve, reject) {
    refData.get().then((snap) => {
      resolve(snap.data())
    })
  })

  return promise
}

export const deleteCollection = (path) => {
  const useRef = firestore
    .collection(path)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((val) => {
        val.ref.delete()
      })
    })
}
