import { firestore, auth } from './Firebase.utils'
import moment from 'moment'
import { collection, addDoc } from 'firebase/firestore'
import firebase from 'firebase/compat/app'

import { updateRoom } from './Firebase.Room'

export const createMessage = async (roomId, uid, uidOther, msg) => {
  if (!roomId || !uid || !uidOther) return

  // const chatroomRef = firestore.collection(`chatrooms`).doc(roomId).set({
  //   lastMessage: msg,
  //   updated: firebase.firestore.FieldValue.serverTimestamp(),
  // })
  const messageRef = firestore.collection(`chatrooms/${roomId}/messages/`)
  try {
    const created = firebase.firestore.FieldValue.serverTimestamp()
    const sendData = {
      roomId: roomId,
      created: created,
      msg: msg,
      receiverId: firestore.doc(`users/${uidOther}`),
      senderId: firestore.doc(`users/${uid}`),
    }
    await messageRef.add(sendData)

    updateRoom(roomId, {
      lastMessage: msg,
      updated: firebase.firestore.FieldValue.serverTimestamp(),
    })

    return sendData
  } catch (error) {
    console.log('11111', error.message)
  }

  // const messageRef = firestore.collection(`messages`)
  // try {
  //   const weight1 = calculateWeight(uid)
  //   const weight2 = calculateWeight(uidOther)
  //   const val1 = weight1 > weight2 ? uid : uidOther
  //   const val2 = weight2 < weight1 ? uidOther : uid
  //   const chatId = `${val1}_${val2}`
  //   const created = firebase.firestore.FieldValue.serverTimestamp()
  //   const sendData = {
  //     chatId: chatId,
  //     created: created,
  //     msg: msg,
  //     receiverId: firestore.doc(`users/${uidOther}`),
  //     senderId: firestore.doc(`users/${uid}`),
  //   }
  //   await messageRef.add(sendData)
  //   return sendData
  // } catch (error) {
  //   console.log('11111', error.message)
  // }
}

export const getMessage = async (uid, uidOther, limit = 1, page = 1) => {
  if (!uid) return
  var promise = new Promise(function (resolve, reject) {
    const messagesRef = firestore
      .collection(`messages`)
      .orderBy('created', 'desc')
      .where('chatId', 'in', [`${uid}_${uidOther}`, `${uidOther}_${uid}`])
      // .where('uid', '==', 'Qn2xAsx6VCNuthERjL0kjJ98N662')
      .limit(limit)
      .get()
      .then((querySnapshot) => {
        const data = []
        querySnapshot.forEach((documentSnapshot) => {
          // documentSnapshot.ref.delete()
          const messages = documentSnapshot.data()
          messages.messageId = documentSnapshot.id
          data.push(messages)
        })

        resolve(data)
      })
  })

  return promise
}
