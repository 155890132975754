import { useState } from 'react'

import { Button, ButtonPlain } from '../../Styled/Button'
import { Photos } from '../../Styled/Photos'

import { Paragraph } from '../../Styled/Typography'
import styled from 'styled-components'
import { SignInTemplate } from './SignIn'
import { Gap } from '../../Styled/Wrap/Gap'
import { useUploadImages } from '../../Hooks/useUploadImages'

const SignupUpload = (props) => {
  const { onSetData } = props
  const [photos, setPhotos] = useState([])
  const useUpload = useUploadImages({ userImages: [] })

  const updateState = (id, value) => {
    setPhotos(value)
  }

  const submit = () => {
    onSetData('photos', photos)
  }

  const isValidForm = () => {
    return photos && photos.length > 0
  }

  return (
    <SignInTemplate title={`Upload your photos`}>
      <Paragraph className="text-details">
        Minimum of one and maximum ten.
      </Paragraph>

      <Gap />
      {/* <progress value={progress} max={100} />
        <input type="file" onChange={handleChange} multiple />
        <button onClick={handleUpload}>Upload</button> */}

      <Photos photos={photos} onHandleChange={updateState} />
      <Gap />

      <Button disabled={!isValidForm()} onClick={submit}>
        Next
      </Button>
    </SignInTemplate>
  )
}

export default SignupUpload

const BrowseFiles = styled.input`
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
`
