import { firestore, auth } from './Firebase.utils'
import moment from 'moment'
import { collection, addDoc } from 'firebase/firestore'
import firebase from 'firebase/compat/app'
import { createMessage } from './Firebase.Message'

export const createUserLike = async (options) => {
  if (!auth || !auth.currentUser) return
  const { userId, message } = options
  const ownerId = auth.currentUser.uid

  try {
    await addDoc(collection(firestore, `likes/${ownerId}/send`), {
      message: message,
      userId: firestore.doc(`users/${userId}`),
    })

    await addDoc(collection(firestore, `likes/${userId}/received`), {
      message: message,
      userId: firestore.doc(`users/${ownerId}`),
    })

    const chatId = getRoomId(ownerId, userId)
    firestore
      .collection('chatrooms')
      .doc(chatId)
      .set({
        status: 'inactive',
        created: firebase.firestore.FieldValue.serverTimestamp(),
        updated: firebase.firestore.FieldValue.serverTimestamp(),
        ownerId: firestore.doc(`users/${ownerId}`),
        receiverId: firestore.doc(`users/${userId}`),
        roomId: chatId,
        message: message,
        users: [ownerId, userId],
      })
    await createMessage(chatId, ownerId, userId, message)
  } catch (error) {}
}

export const getRoomId = (uid, uidOther) => {
  const weight1 = calculateWeight(uid)
  const weight2 = calculateWeight(uidOther)
  const val1 = weight1 > weight2 ? uid : uidOther
  const val2 = weight2 < weight1 ? uidOther : uid
  const roomId = `${val1}_${val2}`

  return roomId
}

export const calculateWeight = (str = '') => {
  str = str.toLowerCase()
  const legend = '0123456789abcdefghijklmnopqrstuvwxyz'
  let weight = 0
  const { length: l } = str

  for (let i = 0; i < l; i++) {
    const el = str[i]
    const curr = legend.indexOf(el)
    weight += curr + 1
  }

  return weight
}

// export const loadLikes = async (uid, type = 'received', limit = 20) => {
//   var promise = new Promise(function (resolve, reject) {
//     const userRef = firestore
//       .collection(`likes/${uid}/${type}`)
//       .limit(limit)
//       .get()
//       .then((querySnapshot) => {
//         const data = []
//         querySnapshot.forEach((documentSnapshot) => {
//           const likes = documentSnapshot.data()
//           likes.likeId = documentSnapshot.id
//           data.push(likes)
//         })

//         resolve(data)
//       })
//   })

//   return promise
// }
