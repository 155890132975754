import { useState } from 'react'

import { Label } from '../../Styled/List/List'
import { Gap } from '../../Styled/Wrap/Gap'
import { Button } from '../../Styled/Button/Button'
import { getPreferenceOptions } from '../../Services/UserServices'
import { PageWrap } from '../../Styled/Wrap'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          border: '1px solid #002A5E',
          borderRadius: '3px',
        },
        popper: {
          border: '1px solid #002A5E',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          background: '#eee',
          paddingLeft: '5px',
        },
      },
    },
  },
})

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const CustomAutocomplete = (props) => {
  const {
    options,
    submit,
    label = 'Search...',
    placeholder = 'Search...',
  } = props
  const { defaultValue, id, items, isMultiple = false } = options
  const allSelected = false

  const pageOptions = getPreferenceOptions(id)
  const { title } = pageOptions

  const [value, setValue] = useState<undefined | {}>(defaultValue)
  // useEffect(() => {
  //   if (defaultValue) {
  //     setValue(defaultValue)
  //   }
  // }, [defaultValue])
  // console.log('defaultValue', defaultValue)

  const handleChange = (event, value) => {
    if (value) {
      setValue(value)
    }
  }

  const getSelectedValue = () => {
    console.log('value', value)
    return value
  }

  return (
    <PageWrap padding={20}>
      <Label className="reset">
        <span>{title}</span>
      </Label>
      <ThemeProvider theme={theme}>
        <Autocomplete
          disablePortal
          id="select-dropdown"
          options={items}
          sx={{ width: 300 }}
          multiple={isMultiple}
          // renderInput={(params) => <TextField {...params} label="Movie" />}
          //
          disableCloseOnSelect={isMultiple}
          // getOptionDisabled={(option) => allSelected && option.value !== 'all'}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              {isMultiple && (
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={allSelected ? true : selected}
                  disabled={
                    allSelected
                      ? option?.label == 'all'
                        ? false
                        : true
                      : false
                  }
                />
              )}
              {option?.label ? option?.label : option}
            </li>
          )}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField {...params} label={label} placeholder={placeholder} />
          )}
          value={getSelectedValue()}
          // defaultValue={{ label: 'Accountant', value: 'Accountant' }}
          onChange={handleChange}
          autoSelect={true}
          // isOptionEqualToValue={handleIsOptionEqualToValue}
        />
      </ThemeProvider>

      <Gap />
      <Button onClick={() => submit(value)}>Done</Button>
    </PageWrap>
  )
}

export default CustomAutocomplete
