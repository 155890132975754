import { Button } from '../../Styled/Button'
import { SignInTemplate } from './SignIn'
import { Gap } from '../../Styled/Wrap/Gap'

const SignupGender = (props) => {
  const { onSetData } = props

  const submit = (option) => {
    onSetData('gender', option)
  }

  return (
    <SignInTemplate title={`What's your gender?`}>
      <Gap />
      <Button onClick={() => submit('male')}>Male</Button>
      <Gap />
      <Button onClick={() => submit('female')}>Female</Button>
    </SignInTemplate>
  )
}

export default SignupGender
