import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as IconLike } from '../../assets/svg/like.svg'
import { ReactComponent as IconLiked } from '../../assets/svg/liked.svg'
import { ReactComponent as NoImage } from '../../assets/svg/no-photo.svg'
import Modal from '../../Pages/Modal/Modal'
import LikeModal from '../../Pages/Modal/LikeModal'
import { Heading, Paragraph } from '../Typography'
import { getProfile } from '../../Services/UserServices'
import { withRouter } from 'react-router'
import { createUserLike } from '../../Firebase/Firebase.Likes'
import { createNotification } from '../../Firebase/Firebase.Notification'
import Profile from '../../Pages/Profile'
import { IconPanel } from '../Icons/IconPanel'
import { Image } from '../Icons/Image'
import { UserSelector } from '../../Redux/UserSelector'
import { useSelector, connect } from 'react-redux'
interface ProfilePanelProps {
  lines?: number
  dataItem?: any
  history?: any
  dispatch?: any
}

const ProfilePanel = (props: ProfilePanelProps) => {
  const currentUser = useSelector(UserSelector)
  const { dataItem, lines = 3, dispatch } = props

  const [liked, setLiked] = useState(false)
  const [like, setLike] = useState(false)
  const [user, setUser] = useState(false)
  const [profileVisible, setProfileVisible] = useState(false)
  const { likedQuery } = dataItem

  const title = getProfile('title', dataItem)
  const subTitle = getProfile('occupation', dataItem)
  const shortDescription = getProfile('aboutMe', dataItem)
  const image = dataItem && dataItem.photos && dataItem.photos[0]

  const [lastLiked, setLastLiked] = useState()

  useEffect(() => {
    if (likedQuery) {
      likedQuery.get().then((results) => {
        setLiked(results.size > 0)
      })
    }
  }, [likedQuery, lastLiked])

  const likeCancel = (e) => {
    setLike(false)
  }

  const likeSubmit = async (value: string) => {
    const options = {
      message: value,
      userId: dataItem.userId,
    }

    createUserLike(options)
    setLike(false)
    setTimeout(() => {
      setLastLiked(dataItem.userId)
    }, 500)

    const message = `You've received a new like from ${currentUser.profile.name}`
    await createNotification(dataItem.userId, message, 'like')

    dispatch({
      type: 'notification',
      payload: {
        message: `You've successfully send a new like to ${dataItem.name}`,
        type: 'like',
      },
    })
  }

  const likeShow = (e) => {
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    setLike(true)
  }

  const showUser = () => {
    setUser(dataItem)
    setProfileVisible(true)
    // props.history.push(`/user/${dataItem.userId}`)
  }

  const close = () => {
    setProfileVisible(false)
    setTimeout(() => {
      setUser(false)
    }, 400)
  }

  return (
    <>
      <Modal visible={profileVisible}>
        <Profile profile={user} restrictedView={true} onClose={close} />
      </Modal>

      <Modal visible={like}>
        <LikeModal onCancel={likeCancel} onSubmit={likeSubmit} />
      </Modal>

      <ProfilePanelContainer onClick={showUser}>
        <ProfileImageContainer>
          {image && <Image src={image} />}
          {!image && <NoImage />}
        </ProfileImageContainer>
        <Details>
          <StyledIconPanel onClick={likeShow}>
            {liked && <IconLiked />}
            {!liked && <IconLike />}
          </StyledIconPanel>
          {/* <div className="like" onClick={likeShow}>
            
          </div> */}
          <div className="profile-title">
            <Heading type={'h2'} size={17}>
              {title}
            </Heading>
            <Paragraph>{subTitle}</Paragraph>
          </div>
          <Paragraph lines={lines}>{shortDescription}</Paragraph>
        </Details>
      </ProfilePanelContainer>
    </>
  )
}

export default connect()(withRouter(ProfilePanel))

const ProfileImageContainer = styled.div`
  height: 230px;
  background: #dadada;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ProfilePanelContainer = styled.div`
  .profile-title {
    width: calc(100% - 55px);
    margin-bottom: 10px;
  }

  h2 {
    margin-bottom: 5px;
  }

  img {
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }

  p {
    margin-bottom: 10px;
  }
`

const Details = styled.div`
  padding: 15px 20px 15px 20px;
`

const StyledIconPanel = styled(IconPanel)`
  position: absolute;
  margin-top: -3px;
  right: 20px;
`
