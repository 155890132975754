let INITIAL_STATE = {
  preferencesVisible: false,
  preferences: {
    age: undefined | [],
    height: [4.8, 5.6],
    distance: false,
    ethnicity: [],
    practising: false,
    languages: [],
    education: false,
    group: false,
  },
}

const PreferencesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'togglePreferences':
      return {
        ...state,
        preferencesVisible: action.payload,
      }

    case 'userPreferences':
      return {
        ...state,
        preferences: action.payload,
      }

    case 'userPreference':
      let preferences = state.preferences
      if (action.node) {
        preferences[action.node] = action.payload
      }

      return {
        ...state,
        preferences: preferences,
      }

    default:
      return state
  }
}

export default PreferencesReducer
