import { UserType } from '../Types/User'

let INITIAL_STATE = {
  userProfile: {},
  preferences: {
    age: [18, 54],
    height: [4.8, 5.6],
    distance: false,
    ethnicity: [],
    practising: false,
    languages: [],
    education: false,
    group: false,
  },
}

const UserPofileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'userProfile':
      const { userProfile } = action
      // console.log('payload123', userProfile, state, action)
      // if (action.node) {
      //   payload[action.node] = action.payload
      // }
      return {
        ...state,
        userProfile: { ...userProfile },
      }
    case 'userPreferences':
      let preferences = state.preferences
      if (action.node) {
        preferences[action.node] = action.payload
      }
      return {
        ...state,
        preferences: { ...preferences },
      }
    default:
      return state
  }
}

export default UserPofileReducer
