import { withRouter } from 'react-router'
import styled from 'styled-components'
import { PanelHeader } from '../Styled/Headers'
import {
  PageWrapFullSizeContainer,
  MainPagesWrap,
  MainPagesSpace,
} from '../Styled/Wrap'
import Slider from '../Styled/Slider/Slider'
import { Button } from '../Styled/Button'
import { Heading, Paragraph } from '../Styled/Typography'

import { ListLabel } from '../Styled/List/ListLabel'
import { getSliderPhotos, getProfile } from '../Services/UserServices'
import { Gap } from '../Styled/Wrap/Gap'
import { LikeAccept } from '../Components/LikeAccept'
import { useState } from 'react'
import { getRoomId } from '../Firebase/Firebase.Likes'
import { useSelector } from 'react-redux'
import { UserSelector } from '../Redux/UserSelector'

const Profile = (props) => {
  const [status, setStatus] = useState()
  const { profile, restrictedView = true, onClose } = props
  const userData = useSelector(UserSelector)
  if (!profile) return null

  const title = restrictedView ? getProfile('title', profile) : 'My profile'
  const { photos } = profile

  const roomId = getRoomId(userData.uid, profile.userId)

  const edit = () => {
    if (restrictedView) return

    props.history.push('/profile-edit')
  }

  const listLabels = [
    { id: 'height', title: 'Height' },
    { id: 'ethnicity', title: 'Ethnicity' },
    { id: 'languages', title: 'Language' },
    { id: 'education', title: 'Education' },
    { id: 'group', title: 'Group' },
    { id: 'practising', title: 'Practising level' },
  ]

  const listTexts = [
    { id: 'aboutMe', title: 'About me' },
    { id: 'aboutFamily', title: 'About my family' },
    { id: 'hobby', title: 'Things I like' },
    { id: 'looking', title: 'Looking for someone' },
  ]

  return (
    <Wrapper>
      <PanelHeader title={title} back={onClose} />

      <Slider images={getSliderPhotos(photos)} />

      <MainPagesSpace>
        {!restrictedView && (
          <Button onClick={() => edit()}>Update profile</Button>
        )}

        <Heading size={17} type={'h1'}>
          {getProfile('title', profile)}
        </Heading>
        <Paragraph size={17}>{getProfile('occupation', profile)}</Paragraph>

        <Gap gap={15} />

        <ListLabel>
          {listLabels.map(({ id, title }) => {
            const value = getProfile(id, profile)
            if (!value) return null
            return (
              <li>
                <label>{title}:</label> <strong>{value}</strong>
              </li>
            )
          })}
        </ListLabel>

        <Gap gap={20} />

        {listTexts.map(({ id, title }) => {
          const value = getProfile(id, profile)
          if (!value) return null
          return (
            <>
              <Heading size={14} type={'h2'}>
                {title}
              </Heading>
              <Paragraph size={14}>{value}</Paragraph>
              <Gap gap={10} />
            </>
          )
        })}
      </MainPagesSpace>

      {restrictedView && (
        <LikeAccept
          roomId={roomId}
          updateStatus={setStatus}
          profile={profile}
        />
      )}
      {status === 'active' && <Gap gap={30} />}
      {restrictedView === false && <Gap gap={70} />}
    </Wrapper>
  )
}

export default withRouter(Profile)

const Wrapper = styled(PageWrapFullSizeContainer)`
  background: ${(props) => props.theme.colors.background};
`
