import React from 'react'
import styled from 'styled-components'
import { isMobile, isTablet } from 'react-device-detect'

interface ParagraphProps {
  size?: number
  type?: string
  children?: string | undefined | string[]
  color?: string
  className?: string
  isInnerHTML?: boolean
  lines?: number
}

export const Paragraph = (props: ParagraphProps) => {
  const {
    size,
    type,
    children,
    color,
    className,
    isInnerHTML,
    lines = undefined,
  } = props
  if (isInnerHTML) {
    return (
      <ParagraphContainer
        size={size}
        color={color}
        className={className}
        lines={lines}
      >
        {children}
      </ParagraphContainer>
    )
  }

  return (
    <ParagraphContainer
      size={size}
      color={color}
      className={className}
      lines={lines}
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    ></ParagraphContainer>
  )
}

const ParagraphContainer = styled.p<ParagraphProps>`
  ${({ size }) =>
    size &&
    `
    font-size: ${size}px;
    line-height: (${size} + ${7})px;
  `}

  ${({ color }) =>
    color &&
    `
    color: ${color};
    
    a {
      color: ${color};
    }
  `}

  &.text-details {
    font-size: 14px;
    line-height: 21px;
    color: ${(props) => props.theme.colors.textLight};
  }

  ${({ lines }) =>
    lines &&
    lines > 0 &&
    `
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;    
  `}
`
