import { useState } from 'react'
import { Button } from '../../Styled/Button'
import { Paragraph } from '../../Styled/Typography'
import { Pin } from '../../Styled/Inputs/Phone'
import { SignInTemplate } from './SignIn'
import { Gap } from '../../Styled/Wrap/Gap'
import moment from 'moment'

function SignupDOB(props) {
  const { onSetData } = props

  const [dob, setDob] = useState()

  const submit = () => {
    onSetData('dob', moment(dob, 'DDMMYYYY').unix())
  }

  const isValidForm = () => {
    return dob
  }

  return (
    <SignInTemplate title={`When were you born?`}>
      <Paragraph className="text-details">
        We need to know your age to help match you to your perfect partner,
        InshaAllah.
      </Paragraph>
      <Gap />
      <Pin onSet={setDob} placeholder={'ddmmyyyy'} length={8} />
      <Gap />
      <Button disabled={!isValidForm()} onClick={submit}>
        Next
      </Button>
    </SignInTemplate>
  )
}

export default SignupDOB
