import { useState } from 'react'

import { Label } from '../../Styled/List/List'
import { Gap } from '../../Styled/Wrap/Gap'
import { Button } from '../../Styled/Button/Button'
import { getPreferenceOptions } from '../../Services/UserServices'
import { PageWrap } from '../../Styled/Wrap'
import { Input } from '../../Styled/Inputs/Input'
import { Pin } from '../../Styled/Inputs/Phone'
import moment from 'moment'

const InputType = (props) => {
  const { options, submit } = props
  const { defaultValue, id } = options

  const pageOptions = getPreferenceOptions(id)
  const { title } = pageOptions

  const [value, setValue] = useState(
    defaultValue ? defaultValue : pageOptions['defaultValue']
  )

  const handleChange = (event) => {
    if (id == 'dob') {
      return setValue(moment(event, 'DD/MM/YYYY').unix())
    }

    const { value } = event.target
    setValue(value)
  }

  const getInput = () => {
    switch (id) {
      case 'dob':
        const date =
          defaultValue && moment.unix(defaultValue).format('DDMMYYYY')

        return (
          <Pin
            onSet={handleChange}
            placeholder={'ddmmyyyy'}
            length={8}
            value={date}
          />
        )
    }

    return <Input type="text" onChange={handleChange} value={defaultValue} />
  }

  return (
    <PageWrap padding={20}>
      <Label className="reset">
        <span>{title}</span>
      </Label>

      {getInput()}
      <Gap />
      <Button onClick={() => submit(value)}>Done</Button>
    </PageWrap>
  )
}

export default InputType
