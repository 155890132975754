import { useState } from 'react'

import { Label } from '../../Styled/List/List'
import { Gap } from '../../Styled/Wrap/Gap'
import { Button } from '../../Styled/Button/Button'
import { getPreferenceOptions, getFormatted } from '../../Services/UserServices'
import { PageWrap } from '../../Styled/Wrap'
import { Input } from '../../Styled/Inputs/Input'

const InputType = (props) => {
  const { defaultValue, submit, id } = props

  const pageOptions = getPreferenceOptions(id)
  const { title } = pageOptions

  const [value, setValue] = useState(
    defaultValue ? defaultValue : pageOptions['defaultValue']
  )

  const handleChange = (newValue) => {
    console.log('newValue', newValue)
    setValue(newValue)
  }

  return (
    <PageWrap padding={20}>
      <Label className="reset">
        <span>{title}</span>
      </Label>

      <Input type="text" onChange={handleChange} />
      <Gap />
      <Button onClick={() => submit(value)}>Done</Button>
    </PageWrap>
  )
}

export default InputType
