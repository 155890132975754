import React from 'react'
import styled from 'styled-components'

interface RecaptchaProps {
  children?: React.ReactElement | string
}

export const Recaptcha = (props: RecaptchaProps) => {
  const { children } = props
  return (
    <RecaptchaContainer className="recaptcha" id="recaptcha">
      {children}
    </RecaptchaContainer>
  )
}

const RecaptchaContainer = styled.div`
  display: none;
`
