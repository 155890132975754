import React from 'react'

import styled from 'styled-components'
import { isMobile, isTablet } from 'react-device-detect'
import { withRouter } from 'react-router'

interface ButtonProps {
  onClick?: any
  children?: React.ReactElement | string | string[] | undefined
  disabled?: boolean
  modifier?: string
  width?: number | undefined
  height?: number
  type?: string
  icon?: string
  className?: string
  isMobile?: boolean
  style?: object
  color?: string
  decoration?: string
  align?: string
  to?: string
}

export const FlexButtons = styled.div`
  display: flex;
  gap: 15px;
`
export const Button = (props: ButtonProps) => {
  const {
    onClick,
    disabled,
    width,
    className = '',
    style,
    children,
    to = '',
  } = props

  const goTo = () => {
    console.log('props', props)
  }

  return (
    <ButtonContainer
      className={`${className}`}
      isMobile={isMobile}
      type="button"
      onClick={onClick || goTo}
      disabled={disabled}
      width={width}
      style={style}
    >
      {children}
    </ButtonContainer>
  )
}

export const ButtonLink = (props: ButtonProps) => {
  const {
    onClick,
    disabled,
    width,
    color,
    decoration,
    align,
    children,
    className = '',
  } = props
  return (
    <ButtonLinkContainer
      className={`${className}`}
      type="button"
      onClick={onClick}
      color={color}
      decoration={decoration}
    >
      {children}
    </ButtonLinkContainer>
  )
}

const ButtonContainer = styled.button<ButtonProps>`
  display: inline-block;
  padding: 0 30px;
  border-radius: 3px;
  border: none;
  height: 60px;
  font-size: 16px;
  text-align: center;
  outline: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s;
  font-weight: 700;
  text-decoration: none;

  ${({ isMobile }) =>
    isMobile &&
    `
    display: block;
    width: 100%;
    margin-bottom: 10px;
  `}

  color: ${(props) => props.theme.colors.button.primary.color};
  background: ${(props) => props.theme.colors.button.primary.background};

  ${({ disabled, theme }) =>
    disabled &&
    `
    color: ${theme.colors.button.disabled.color};
    background: ${theme.colors.button.disabled.background};
  `}

  ${({ width }) => width && width > 0 && `padding: 0; width: ${width}px;`}

  &.btn-plain {
    background: none;
    border: 1px solid ${(props) => props.theme.colors.button.primary.background};
    color: ${(props) => props.theme.colors.button.primary.background};
  }

  &.btn-primary {
    &:hover {
      filter: brightness(0.85);
    }
  }

  // width: ${({ width = 'auto' }) => width};
`

export const ButtonPlain = styled(Button)`
  background: none;
  border: 1px solid ${(props) => props.theme.colors.button.primary.background};
  color: ${(props) => props.theme.colors.button.primary.background};
`

const ButtonLinkContainer = styled.button<ButtonProps>`
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  color: ${(props) => props.theme.colors.link};
  ${({ color }) =>
    color &&
    `
    color: ${color};
  `}
  text-decoration: underline;

  ${({ decoration }) =>
    decoration &&
    `
    text-decoration: ${decoration};
  `}
`
