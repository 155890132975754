import styled from 'styled-components'

interface InputProps {
  value?: string
  type?: string
  pattern?: any
  placeholder?: any
  required?: boolean
  onKeyPress?: any
  onChange?: any
}

export const Input = (props: InputProps) => {
  const { type, pattern, placeholder, onKeyPress, required, onChange, value } =
    props

  return (
    <InputContainer
      type={type}
      // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
      pattern={pattern}
      // "[0-9]"
      placeholder={placeholder}
      // "000-000-000-00"
      required={required}
      onKeyPress={onKeyPress}
      onChange={onChange}
      value={value}
      // restrictNumbers
    />
  )
}

export const InputContainer = styled.input`
  border: 1px solid transparent;
  width: 100%;
  padding: 10px;
  outline: none;
  color: ${(props) => props.theme.colors.text};
  box-sizing: border-box;

  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.input.fontSize};
  box &::placeholder {
    color: ${(props) => props.theme.colors.textDisabled};
  }
`
