import { useEffect, useState } from 'react'
import { MainHeader } from '../Styled/Headers'
import { MainPagesWrap, PageWrapFullSizeContainer } from '../Styled/Wrap'
import { createUserProfile } from '../Firebase/Firebase.CreateUserProfile'
import {
  adminLoadProfiles,
  deleteDummyUsers,
} from '../Firebase/Firebase.Admin.Profiles'
import { createTags } from '../Firebase/Firebase.CreateUserProfile'
import { Gap } from '../Styled/Wrap/Gap'
import { useSelector } from 'react-redux'
import { UserSelector } from '../Redux/UserSelector'
import { PreferencesSelector } from '../Redux/PreferencesSelector'
import { H3 } from '../Styled/Typography'
import TableProfilePanel from './Components/TableProfilePanel'
import styled from 'styled-components'
import { Button } from '../Styled/Button'
import { Hobbies, Names, Photos } from './Components/Data'
import moment from 'moment'
import EthnicGroups from '../Data/EthnicGroups'
import Group from '../Data/Group'
import Languages from '../Data/Languages'
import Occupations from '../Data/Occupations'
import Practicing from '../Data/Practicing'

export const Users = (props) => {
  const [profiles, setProfiles] = useState()
  const user = useSelector(UserSelector)
  const preferences = useSelector(PreferencesSelector)

  const keys = [
    'uid',
    'created',
    'updated',

    'aboutFamily',
    'aboutMe',

    'displayName',
    'name',

    'dob',
    'education',
    'email',

    'ethnicity',
    'gender',
    'group',
    'height',
    'hobby',
    'languages',
    'looking',

    'occupation',
    'phoneNumber',
    'photos',
    'practising',
    'status',
    'role',
  ]

  useEffect(() => {
    if (user) {
      adminLoadProfiles(user).then((data) => {
        setProfiles(data)
      })
    }
  }, [user, preferences])

  const empty = () => {
    if (!profiles || profiles.length === 0) {
      return (
        <MainPagesWrap>
          <H3>No profiles found, try to change search creteria</H3>
        </MainPagesWrap>
      )
    }
  }

  const generateValue = (key: string, options?: []) => {
    switch (key) {
      case 'uid':
        return Date.now()
      case 'created':
      case 'updated':
        return new Date()
      case 'displayName':
      case 'name':
        return getRandom(Names)
      case 'dob':
        return (
          moment()
            .subtract(Math.floor(Math.random() * 40 + 18), 'years')
            .unix() * 1
        )

      case 'education':
        return Math.floor(Math.random() * (7 - 1 + 1)) + 1
      case 'email':
        return generateValue('name') + '@salam.app'
      case 'ethnicity':
        return getRandom(EthnicGroups)
      case 'gender':
        const gender = ['male', 'female']
        return getRandom(gender)

      case 'group':
        return getRandom(Group)
      case 'height':
        return Math.floor(Math.random() * 7)
      case 'hobby':
        return getRandom(Hobbies)
      case 'languages':
        return [
          getRandom(Languages),
          getRandom(Languages),
          getRandom(Languages),
        ]

      case 'occupation':
        return getRandom(Occupations)
      case 'practising':
        return getRandom(Practicing).id
      case 'phoneNumber':
        return '098763663882'
      case 'photos':
        return [getRandom(Photos)]
      case 'status':
        return 'active'
      case 'tags':
        const tags: string[] = createTags(options)
        return tags
      case 'role':
        return 'demo'

      case 'looking':
      case 'aboutFamily':
      case 'aboutMe':
        return 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for.'
    }
  }

  const getRandom = (items: any) => {
    return items[Math.floor(Math.random() * items.length)]
  }

  const onCreateUser = (total: any = false) => {
    for (let i = 1; i < 20; i++) {
      const options = {}
      keys.map((key: string) => {
        options[key] = generateValue(key)
      })
      // options['tags'] = generateValue('tags', options)
      options['uid'] = options['uid'] + i
      createUserProfile(options, options).then((data) => {})
    }

    setTimeout(() => {
      window.location.reload()
    }, 3000)
  }

  const onDeleteDummyUser = () => {
    deleteDummyUsers().then(() => {
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    })
  }

  if (!user) return null

  return (
    <PageWrapFullSizeContainer>
      <MainHeader />

      <GroupedActions>
        <Button onClick={() => onCreateUser(20)}>Create 20 Dummy users</Button>
        <Button onClick={() => onDeleteDummyUser()}>Delete Dummy users</Button>
      </GroupedActions>

      {empty()}

      <FlexContainer>
        {profiles &&
          profiles.map((profile) => {
            return <TableProfilePanel dataItem={profile} />
          })}
      </FlexContainer>
      <Gap gap={60} />
    </PageWrapFullSizeContainer>
  )
}

const FlexContainer = styled.div`
  display: flex;
  gap: 20px;
  margin: 20px;
  flex-direction: column;
`

const GroupedActions = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px 0 0 20px;
`
