import { useState } from 'react'
import { connect } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'

import SignupGender from './SignupGender'
import SignupUpload from './SignupUpload'
import SignupDOB from './SignupDOB'
import SignupHeight from './SignupHeight'
import SignupName from './SignupName'
import { withRouter } from 'react-router-dom'

import { updateUserProfile } from '../../Firebase/Firebase.CreateUserProfile'

const SignUp = (props) => {
  const { dispatch, createUser, user, history } = props
  const [currentSlide, setCurrentSlide] = useState(0)
  const [userData, setUserData] = useState()

  const next = (direction) => {
    if (direction == undefined) {
      direction = 1
    }
    setCurrentSlide(currentSlide + direction)
  }

  const updateUser = () => {
    setData('status', 'active')

    updateUserProfile(user, userData).then((userData) => {
      if (user && userData) {
        user.profile = userData
        props.dispatch({ type: 'login', payload: user })
      }
      history.push('/home')
    })
    // updateUserProfile(user, userData) //createUser
    // history.push('/home')
  }

  const setData = (node, option) => {
    const data = userData ? userData : {}
    data[node] = option
    setUserData(data)

    // dispatch({
    //   type: 'createUser',
    //   node: 'gender',
    //   payload: option,
    // })
    next(1)
  }

  const scrollUp = () => {
    window.scrollTo(0, 0)
  }

  return (
    <Carousel
      selectedItem={currentSlide}
      showArrows={false}
      showThumbs={false}
      autoPlay={false}
      centerMode={false}
      dynamicHeight={false}
      infiniteLoop={false}
      autoFocus={true}
      showIndicators={false}
      swipeable={false}
      showStatus={false}
      onChange={scrollUp}
    >
      <SignupName onSetData={setData} />
      <SignupGender onSetData={setData} />
      <SignupUpload onSetData={setData} />
      <SignupDOB onSetData={setData} />
      <SignupHeight onSave={updateUser} onSetData={setData} />
    </Carousel>
  )
}

const mapStateToProps = (state) => ({
  createUser: state.createUser.createUser,
  user: state.auth.user,
})
export default connect(mapStateToProps)(withRouter(SignUp))
