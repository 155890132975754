import React, { useState } from 'react'
import styled from 'styled-components'

interface TextareaProps {
  placeholder: string
  children?: any
  onChange?: any
  name?: string
}

export const Textarea = (props: TextareaProps) => {
  const { placeholder, children, name, onChange } = props

  const adjustSize = (event) => {
    const element = event.target
    // element.style.height = '1px'
    element.style.height = element.scrollHeight + 'px'
  }

  return (
    <TextareaContainerDiv
      id={name}
      placeholder={placeholder}
      // onChange={onChange}
      contentEditable
      onKeyUp={onChange}
      dangerouslySetInnerHTML={{ __html: children }}
    ></TextareaContainerDiv>
  )
}

const TextareaContainerDiv = styled.div`
  display: block;
  border-radius: 5px;
  overflow: auto;
  width: 100%;
  min-height: 50px;
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
  color: ${(props) => props.theme.colors.text};
  box-sizing: border-box;
  background: #fff;
`

const TextareaContainer = styled.textarea`
  display: block;
  border-radius: 5px;
  overflow: auto;
  width: 100%;
  min-height: 50px;
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
  color: ${(props) => props.theme.colors.text};
  box-sizing: border-box;
  background: #fff;

  resize: none;
`
