import { useState, useEffect } from 'react'
import { withRouter } from 'react-router'

import { Switch, Route, Redirect } from 'react-router-dom'

import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import './assets/scss/reset.scss'
import './assets/scss/index.scss'

import Theme from './Theme'
import Landing from './Pages/Landing'
import MainTemplate from './Pages/MainTemplate'
import SignIn from './Pages/User/SignIn'
import SignUp from './Pages/User/SignUp'
import Preferences from './Pages/Preferences/Preferences'
import Home from './Pages/Home'
import Likes from './Pages/Likes'
import Messages from './Pages/Messages/Messages'
import Message from './Pages/Messages/Message'
import ProfileMy from './Pages/ProfileMy'
import ProfileEdit from './Pages/ProfileEdit'
import { MainFooter, HeaderPlaceholder } from './Styled/Headers'
import PreferencesModal from './Pages/Modal/PreferencesModal'
import { auth } from './Firebase/Firebase.utils'
import { createUserProfile } from './Firebase/Firebase.CreateUserProfile'

import { useAuthState } from 'react-firebase-hooks/auth'

import { Recaptcha } from './Styled/Panels'
import { SecuredRoute } from './Pages/Secured'
import Notification from './Components/Notification'
import { Users } from './Admin/Users'

const App = (props) => {
  const [pathname, setPathname] = useState('')
  const [defaultURl, setDefaultURl] = useState(undefined)
  const [className, setClassName] = useState('pageRight')
  const [user] = useAuthState(auth)

  // useEffect(() => {
  //   if (user && user.profile && user.profile.status == 'active') {
  //     setDefaultURl('/home')
  //   }
  // }, [user, auth])

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const userProfile = createUserProfile(user).then((userProfile) => {
          if (userProfile) {
            user.profile = userProfile
          }
          props.dispatch({ type: 'login', payload: user })
          if (userProfile.preferences) {
            props.dispatch({
              type: 'userPreferences',
              payload: userProfile.preferences,
            })
          }

          if (!isFullUser(userProfile)) {
            setDefaultURl('/sign-up')
          } else if (unSignIn()) {
            setDefaultURl('/home')
          }
        })
      } else {
        props.dispatch({ type: 'login', payload: undefined })
        props.dispatch({
          type: 'userPreferences',
          payload: undefined,
        })
        setDefaultURl('/')
      }
    })
  }, [user])

  const unSignIn = () => {
    if (['/', '/sign-in', '/sign-up'].includes(props.location.pathname)) {
      return true
    }

    return false
  }

  const isFullUser = (user) => {
    if (user.name) {
      return true
    }

    return false
  }

  const redirect = () => {
    if (defaultURl) {
      return <Redirect to={defaultURl} />
    }
  }

  const animateDirection = (location) => {
    const depth = [
      'sign-in',
      'sign-up',
      'home',
      'user',
      'likes',
      'messages',
      'message',
      'profile',
      'profile-edit',
      'preferences',
    ]
    const path = location.pathname

    if (pathname == path) {
      return className
    }

    const cleanPathTmp = path.split('/')
    const cleanPathnameTmp = pathname.split('/')

    const cleanPath = cleanPathTmp && cleanPathTmp[1] ? cleanPathTmp[1] : false
    const cleanPathname =
      cleanPathnameTmp && cleanPathnameTmp[1] ? cleanPathnameTmp[1] : false

    const animClassName =
      depth.indexOf(cleanPath) >= depth.indexOf(cleanPathname)
        ? 'pageLeft'
        : 'pageRight'
    setClassName(animClassName)
    setPathname(path)
    console.log('animClassName', animClassName)
    return animClassName
  }

  return (
    <Theme>
      <Recaptcha />
      {redirect()}
      <PreferencesModal />
      {user && <Notification user={user} />}
      <Route
        render={({ location }) => {
          return (
            <MainTemplate className={animateDirection(location)}>
              <TransitionGroup component={null}>
                <CSSTransition
                  key={location.pathname}
                  classNames="page"
                  timeout={500}
                  mountOnEnter={false}
                  unmountOnExit={true}
                >
                  <Switch location={location}>
                    <Route exact path="/">
                      <Landing />
                    </Route>
                    <Route exact path="/sign-in">
                      <SignIn />
                    </Route>
                    <Route exact path="/sign-up">
                      <SignUp />
                    </Route>

                    <Route exact path="/preferences">
                      <Preferences />
                    </Route>
                    <Route exact path="/home">
                      <Home />
                    </Route>
                    <Route exact path="/likes">
                      {user && <Likes />}
                    </Route>
                    <Route exact path="/messages">
                      {user && <Messages user={user} />}
                    </Route>
                    <Route exact path="/message/:id">
                      <Message />
                    </Route>

                    <Route exact path="/profile">
                      <ProfileMy />
                    </Route>
                    {/* <Route exact path="/user/:id">
                      <ProfileUser />
                    </Route> */}

                    <Route exact path="/profile-edit">
                      <ProfileEdit />
                    </Route>

                    <Route exact path="/users">
                      <Users />
                    </Route>
                  </Switch>
                </CSSTransition>
              </TransitionGroup>

              <HeaderPlaceholder location={location} />
              <MainFooter location={location} />
            </MainTemplate>
          )
        }}
      />
    </Theme>
  )
}

// const mapStateToProps = (state) => ({
//   user: state.auth.user,
// })
export default connect()(withRouter(App))
