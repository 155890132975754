import { combineReducers } from 'redux'

import PreferencesReducer from './Preferences.Reducer'
import AuthReducer from './Auth.Reducer'
import CreateUserReducer from './CreateUser.Reducer'
import UserPofileReducer from './UserProfile.Reducer'
import NotificationReducer, {
  NotificationMessagesReducer,
  NotificationLikesReducer,
} from './Notification.Reducer'

export default combineReducers({
  preferences: PreferencesReducer,
  auth: AuthReducer,
  createUser: CreateUserReducer,
  notification: NotificationReducer,
  notificationMessages: NotificationMessagesReducer,
  notificationLikes: NotificationLikesReducer,
  userProfile: UserPofileReducer,
})
