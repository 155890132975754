import { PanelHeader } from '../../Styled/Headers'
import { Button, ButtonLink } from '../../Styled/Button'
import { StickyBottom } from '../../Styled/Wrap'
import { List } from '../../Styled/List'
import { PreferencesInnerContainer } from './Preferences'
import { connect } from 'react-redux'
import { Label } from '../../Styled/List/List'
import {
  getHeight,
  getFormatted,
  getEducation,
  getPractising,
} from '../../Services/UserServices'
import styled, { withTheme } from 'styled-components'

function PreferencesList(props) {
  const { goTo, onSubmit, onReset, availableItems, dataSource } = props

  const getValue = (id) => {
    if (!dataSource) return undefined

    const value = dataSource[id]

    if (id == 'dob' && value && value) {
      return value
    }

    if (Array.isArray(value)) {
      if (id == 'height') {
        return `${getHeight(value[0])} - ${getHeight(value[1])}`
      }

      if (value[0] * 1 > 0) {
        return value.join(' - ')
      }

      return value.join(', ')
    }

    if (id == 'height') {
      return getHeight(value)
    }

    if (id == 'education') {
      return getEducation(value)
    }

    if (id == 'practising') {
      return getPractising(value)
    }

    return value
  }

  let prefItems = [
    // { id: 'name', title: 'Name', value: getValue('name') },
    { id: 'occupation', title: 'Occupation', value: getValue('occupation') },
    { id: 'age', title: 'Age', value: getValue('age') },
    // {
    //   id: 'dob',
    //   title: 'Date of birth',
    //   value: getFormatted(getValue('dob'), 'dob'),
    // },
    { id: 'location', title: 'Location', value: getValue('location') },
    {
      id: 'height',
      title: 'Height',
      value: getValue('height'),
    },
    {
      id: 'distance',
      title: 'Maximum distance',
      value: getFormatted(getValue('distance'), 'distance'),
    },
    {
      id: 'ethnicity',
      title: 'Ethnicity',
      value: getValue('ethnicity'),
    },
    {
      id: 'languages',
      title: 'Language',
      value: getValue('languages'),
    },
    {
      id: 'education',
      title: 'Highest education',
      value: getValue('education'),
    },
    { id: 'group', title: 'Group', value: getValue('group') },
    {
      id: 'practising',
      title: 'Practising level',
      value: getValue('practising'),
    },
  ]

  prefItems = prefItems.filter((item) => {
    return availableItems.includes(item.id)
  })

  return (
    <PreferencesInnerContainer>
      {!props.isPlain && <PanelHeader title="Your preferences" />}

      <div className="scrollable">
        <List>
          {prefItems.map((item, index) => (
            <li key={index} onClick={() => goTo(item)}>
              <Label>
                <span>{item.title}</span>
                <span>
                  <strong>{item.value ? item.value : 'Not set'}</strong>
                </span>
              </Label>
            </li>
          ))}
        </List>

        <StickyBottom>
          {!props.isPlain && <Button onClick={onSubmit}>Done</Button>}
          {!props.isPlain && (
            <ResetContainer>
              <ResetButton onClick={onReset}>Reset</ResetButton>
            </ResetContainer>
          )}
        </StickyBottom>
      </div>
    </PreferencesInnerContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    preferences: state.preferences.preferences,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(withTheme(PreferencesList))

const ResetContainer = styled.div`
  text-align: center;
`

const ResetButton = styled(ButtonLink)`
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
`
