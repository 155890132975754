import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import { Client as Styletron } from 'styletron-engine-atomic'
import { Input, InputContainer } from './Input'
import { MenuItem, Select } from '@mui/material'

const engine = new Styletron()

interface PhoneProps {
  onSet?: any
}

interface PinProps {
  placeholder?: string
  onSet?: any
  length: number
  type?: string
  value?: string
}

export const Phone = (props: PhoneProps) => {
  const { onSet } = props
  const country = 44

  const setTextPhone = (event) => {
    const { name, value } = event.target
    onSet('+' + country + value.replace(/^0+/, ''))
  }

  const restrictNumbers = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault()
    }
  }

  return (
    <PhoneContainer>
      <Country
        readOnly
        value={country}
        displayEmpty
        label="Age"
        inputProps={{
          'aria-label': 'Without label',
        }}
      >
        <MenuItem value={44}>GB +44</MenuItem>
      </Country>

      <Input
        type="tel"
        // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
        pattern="[0-9]"
        placeholder="01234567890"
        required
        onKeyPress={restrictNumbers}
        onChange={setTextPhone}
      />
    </PhoneContainer>
  )
}

export const Pin = (props: PinProps) => {
  const {
    onSet,
    placeholder = [],
    length = 6,
    type = 'tel',
    value = '',
  } = props

  let refs = []
  for (let i = 0; i < length; i++) {
    refs[i] = React.createRef()
  }

  const onChange = (e) => {
    const { name, value } = e.target
    let direction = value ? 1 : -1
    refs[name * 1 + direction]?.current.focus()

    let values = []
    refs.map((ref) => {
      if (ref.current.value) {
        values.push(ref.current.value)
      }
    })

    if (values.join('').length === length) {
      onSet(values.join(''))
    } else {
      onSet(false)
    }
  }

  const onUpdate = (e) => {
    const { name, value } = e.target
    if (!value && e.which == 8) {
      refs[name * 1 - 1]?.current.focus()
      e.preventDefault()
    }

    if (value.length > 0 && e.which !== 8) {
      e.target.value = ''
    }
  }

  return (
    <PinContainer>
      {[...Array(length)].map((x, i) => (
        <PinInput
          name={i.toString()}
          key={i}
          type={type}
          maxLength={1}
          placeholder={placeholder[i]}
          defaultValue={value && value[i]}
          onChange={onChange}
          onKeyDown={onUpdate}
          ref={refs[i]}
        />
      ))}
    </PinContainer>
  )
}

const PhoneContainer = styled.div`
  display: flex;
  gap: 10px;
`
const Country = styled(Select)`
  width: 120px;
  background: #dddddd;
  border: none;
  .MuiSelect-select {
    font-size: 18px;
    color: #f00;
    -webkit-text-fill-color: rgba(34, 34, 34, 1) !important;
  }
  fieldset {
    border: none;
  }
`

const PinContainer = styled.div`
  display: flex;
  gap: 7px;
`
const PinInput = styled(InputContainer)`
  flex: 1;
  height: 50px;
  text-align: center;
  font-size: 18px;
  padding: 0;
`
