import styled from 'styled-components'
import { Image } from '../Icons/Image'
import logo from '../../assets/svg/logo-header.svg'

export const HeaderPlaceholder = (props) => {
  const { location } = props

  if (
    ['/home', '/likes', '/messages', '/profile', '/profile-edit'].indexOf(
      location.pathname
    ) === -1
  )
    return null
  return (
    <>
      {/* <Logo>
        <img src={logo} className="logo" />
      </Logo> */}
      <HeaderPlaceholderContainer></HeaderPlaceholderContainer>
    </>
  )
}
const HeaderPlaceholderContainer = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  height: 60px;
  background: ${(props) => props.theme.colors.header.background};
`

const Logo = styled.div`
  position: fixed;
  z-index: 111;
  height: 60px;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 18px;
`
