import { useState } from 'react'
import { Button } from '../../Styled/Button'
import { List } from '../../Styled/List'
import { Radio, RadioGroup } from '@material-ui/core'
import { Checkbox } from '@material-ui/core'
import { Label } from '../../Styled/List/List'
import { Gap } from '../../Styled/Wrap/Gap'
import { getPreferenceOptions } from '../../Services/UserServices'
import { StickyBottom } from '../../Styled/Wrap/Wrap'
import { withTheme } from 'styled-components'

const SelectableType = (props) => {
  const { options, submit } = props
  const { defaultValue, id, isProfile } = options
  console.log('defaultValue', defaultValue)

  const pageOptions = getPreferenceOptions(id, isProfile)
  const { items, multiple = false } = pageOptions

  const [value, setValue] = useState(defaultValue)
  const handleChange = (event) => {
    if (multiple) {
      let val = value ? value : []
      const currentValue = event.target.value
      if (val.indexOf(currentValue) >= 0) {
        val.splice(val.indexOf(currentValue), 1)
      } else {
        val.push(currentValue)
      }
      setValue([...val])
    } else {
      setValue(event.target.value)
    }
  }

  const getCode = (item: any) => {
    if (typeof item === 'string') return item
    if (item.id) return item.id

    return item
  }

  const getText = (item: any) => {
    if (typeof item === 'string') return item

    if (item.title) return item.title

    return item
  }

  return (
    <div className="scrollable">
      {!multiple && (
        <RadioGroup
          aria-label="quiz"
          name="quiz"
          value={value}
          onChange={handleChange}
        >
          <List>
            {items.map(function (item) {
              return (
                <li key={getCode(item)}>
                  <Label>
                    <span>{getText(item)}</span>
                    <span>
                      <Radio
                        checked={
                          value?.toString() === getCode(item)?.toString()
                        }
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        classes={{ root: 'custom-checkbox-root' }}
                        value={getCode(item)}
                      />
                    </span>
                  </Label>
                </li>
              )
            })}
          </List>
        </RadioGroup>
      )}

      {multiple && (
        <List>
          <ul>
            {items.map(function (item) {
              return (
                <li key={item}>
                  <Label>
                    <span>{item}</span>
                    <span>
                      <Checkbox
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        classes={{ root: 'custom-checkbox-root' }}
                        value={item}
                        onChange={handleChange}
                        checked={value && value.indexOf(item) >= 0}
                      />
                    </span>
                  </Label>
                </li>
              )
            })}
          </ul>
        </List>
      )}

      <Gap />
      <StickyBottom>
        <Button onClick={() => submit(value)}>Done</Button>
      </StickyBottom>
    </div>
  )
}

export default withTheme(SelectableType)
