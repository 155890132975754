import { useState } from 'react'
import { Button } from '../../Styled/Button'
import { CustomSlider } from '../../Styled/Inputs/Slider'
import { Paragraph } from '../../Styled/Typography'
import { SignInTemplate } from './SignIn'
import { Gap } from '../../Styled/Wrap/Gap'

function SignupHeight(props) {
  const { onSetData, onSave } = props

  const [height, setHeigt] = useState(4.8)

  const submit = () => {
    onSetData('height', height)
    onSave()
  }

  const isValidForm = () => {
    return height > 0
  }

  return (
    <SignInTemplate title={`How tall are you?`}>
      <Paragraph className="text-details">
        We need to know your age to help match you to your perfect partner,
        InshaAllah.
      </Paragraph>

      <Gap />
      <CustomSlider
        step={0.1}
        min={4.8}
        max={6.8}
        defaultValue={4.8}
        onChange={setHeigt}
      />
      <Gap />

      <Button disabled={!isValidForm()} onClick={submit}>
        Save
      </Button>
    </SignInTemplate>
  )
}

export default SignupHeight
