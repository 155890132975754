import { useState } from 'react'
import styled from 'styled-components'
import { Button, ButtonLink } from '../../Styled/Button'
import { PageWrap } from '../../Styled/Wrap'

const LikeModal = (props) => {
  const { onCancel, onSubmit } = props
  const [comment, setComment] = useState()

  const cancel = () => {
    setTimeout(() => {
      onCancel()
    }, 400)
  }

  const submit = () => {
    onSubmit(comment)
    cancel()
  }

  const handleChange = (e) => {
    const value = e.target.value
    setComment(value)
  }

  return (
    <LikeModalContainer>
      <PageWrap padding={20}>
        {/* <Textarea
          placeholder={'Write a comment with the like for a better response...'}
        ></Textarea> */}
        <textarea
          placeholder={'Write a comment with the like for a better response...'}
          onChange={handleChange}
        ></textarea>
        <Button onClick={submit}>Send like</Button>
        <div className="secondary-button">
          <ButtonLink
            onClick={cancel}
            className="text-center"
            color="#222222"
            decoration="none"
            align="center"
          >
            Cancel
          </ButtonLink>
        </div>
      </PageWrap>
    </LikeModalContainer>
  )
}

export default LikeModal

const LikeModalContainer = styled.div`
  background: #fff;
  width: 100%;
  margin: 20px;

  textarea {
    width: 100%;
    height: 120px;
    color: ${(props) => props.theme.colors.text};
    border: none;
    outline: none;
    resize: none;
    box-sizing: border-box;
  }
`
