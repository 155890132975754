import { useState } from 'react'
import { Button } from '../../Styled/Button'
import { PageWrap } from '../../Styled/Wrap'
import { Heading } from '../../Styled/Typography'
import { Input } from '../../Styled/Inputs/Input'
import { Gap } from '../../Styled/Wrap/Gap'

const SignupName = (props) => {
  const { onSetData } = props
  const [name, setName] = useState('')

  const submit = () => {
    onSetData('name', name)
  }

  const isValidForm = () => {
    return name
  }

  const handleChange = (event) => {
    const { value } = event.target
    setName(value)
  }

  return (
    <PageWrap className="wrap-secondary">
      <Heading size={30} type={'h1'}>
        What's your name?
      </Heading>

      <Gap />
      <Input type="text" onChange={handleChange} />
      <Gap />
      <Button disabled={!isValidForm()} onClick={submit}>
        Next
      </Button>
    </PageWrap>
  )
}

export default SignupName
