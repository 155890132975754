import styled from 'styled-components'

export const List = styled.ul`
  li {
    &:hover,
    &:active {
      background: ${(props) => props.theme.colors.listHover};
    }
  }
`

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin-bottom: 0;
  font-size: 16px;

  &.reset {
    padding-left: 0;
    padding-right: 0;
  }
`
