import styled from 'styled-components'
import { Button, ButtonPlain, FlexButtons } from '../Styled/Button/Button'
import { Gap } from '../Styled/Wrap/Gap'
import { ReactComponent as Tick } from '../assets/svg/tick.svg'
import { firestore, auth } from '../Firebase/Firebase.utils'
import { updateRoom } from '../Firebase/Firebase.Room'
import { doc, onSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { UserSelector } from '../Redux/UserSelector'
import { ReactComponent as IconMessages } from '../assets/svg/footer-messages.svg'
import { IconPanel } from '../Styled/Icons/IconPanel'
import Modal from '../Pages/Modal/Modal'
import Message from '../Pages/Messages/Message'

export const LikeAccept = (props) => {
  const { roomId, updateStatus, canMessage = true, profile } = props
  const [status, setStatus] = useState(undefined)
  const [visibleMessage, setVisibleMessage] = useState(false)

  const [isSameUser, setIsSameUser] = useState(undefined)
  const user = useSelector(UserSelector)

  useEffect(() => {
    if (roomId && user) {
      onSnapshot(doc(firestore, 'chatrooms', roomId), (doc) => {
        const data = doc.data()
        console.log('data', data, roomId)
        if (data) {
          const status = data?.status
          setStatus(status)
          updateStatus(status)
          setIsSameUser(user.uid === data.ownerId.id)
        }
      })
    }
  }, [user])

  if (canMessage === false && status === 'active') return null
  if (status === undefined) return null

  const onAccept = () => {
    updateRoom(roomId, { status: 'active' })
  }

  const onDecline = () => {
    updateRoom(roomId, { status: 'declined' })
  }

  const showMessage = () => {
    setVisibleMessage(true)
  }

  const hideMessage = () => {
    setVisibleMessage(false)
  }

  if (status === 'active') {
    return (
      <>
        <Modal visible={visibleMessage}>
          <Message roomId={roomId} onBack={() => hideMessage()} />
        </Modal>
        <PositionRight>
          <IconPanel onClick={() => showMessage()}>
            <IconMessages />
          </IconPanel>
        </PositionRight>
      </>
    )
  }

  if (isSameUser) {
    return (
      <Container>
        You liked this profile, once you like will accepted you can start
        chatting
      </Container>
    )
  }

  return (
    <Container>
      <strong>{profile?.name}</strong> has liked your profile and would like to
      connect with you.
      <StyledFlexButtons>
        <StyleButtonPlain onClick={onDecline}>Decline</StyleButtonPlain>
        <Button onClick={onAccept}>
          <Tick /> Accept
        </Button>
      </StyledFlexButtons>
    </Container>
  )
}

const Container = styled.div`
  position: sticky;
  bottom: 0;
  background: ${({ theme }) => theme.colors.backgroundEvent};
  padding: 10px 20px;
`

const StyleButtonPlain = styled(ButtonPlain)`
  border: 1px solid #000000;
  color: ${({ theme }) => theme.colors.text};
`

const StyledFlexButtons = styled(FlexButtons)`
  margin-top: 15px;
`

const PositionRight = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
`
