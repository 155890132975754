import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Preferences from '../Preferences/Preferences'
import Modal from '../Modal/Modal'
import { MainTemplateContainer } from '../MainTemplate'

const PreferencesModal = (props) => {
  // useEffect(() => {
  //   if (props.preferencesVisible > 0) {
  //     handleUpload()
  //   }
  // }, [props.preferencesVisible])

  const [visible, setVisible] = useState(true)

  if (!props.preferencesVisible) {
    return null
  }

  const close = () => {
    setVisible(false)
    setTimeout(() => {
      props.dispatch({
        type: 'togglePreferences',
        payload: false,
      })
      setVisible(true)
    }, 400)
  }

  return (
    <PreferencesModalContainer>
      <Modal visible={visible} animation={'slide'}>
        <Preferences onClose={close} />
      </Modal>
    </PreferencesModalContainer>
  )
}

const PreferencesModalContainer = styled(MainTemplateContainer)`
  // position: fixed;
  // z-index: 200;
  // left: 0;
  // right: 0;
  // top: 0;
`

const mapStateToProps = (state) => ({
  preferencesVisible: state.preferences.preferencesVisible,
})
export default connect(mapStateToProps)(PreferencesModal)
