import styled from 'styled-components'

interface ProfileImageProps {
  image?: any
  size?: string
  border?: string
}

export const ProfileImage = (props: ProfileImageProps) => {
  const { image, size, border } = props

  if (!image) return null

  return <ProfileImageContainer size={size} border={border} image={image} />
}

const ProfileImageContainer = styled.div<ProfileImageProps>`
  background: url(${({ image }) => image});
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-size: cover;

  ${({ border }) =>
    border &&
    `
      border: 1px solid ` +
      border +
      `;
    `}

  ${({ size }) =>
    size &&
    `
      width: ` +
      size +
      `;
      height: ` +
      size +
      `;
    `}
`
