import styled from 'styled-components'
import logo from '../../assets/svg/logo-header.svg'
import preference from '../../assets/svg/prefenrece.svg'
import { connect } from 'react-redux'
import { Image } from '../Icons/Image'

interface MainHeaderProps {
  title?: string
  back?: any
  dispatch?: any
}

const MainHeader = (props: MainHeaderProps) => {
  const { title, back } = props

  const changePreferences = (status) => {
    props.dispatch({ type: 'togglePreferences', payload: true })
  }

  return (
    <MainHeaderContainer>
      <div>
        <Image src={logo} className="logo" />
      </div>

      <div>
        <button onClick={changePreferences}>
          <img src={preference} /> Preferences
        </button>
      </div>
    </MainHeaderContainer>
  )
}

const MainHeaderContainer = styled.header<MainHeaderProps>`
  position: sticky;
  top: 0;
  display: flex;
  height: 60px;
  background: ${(props) => props.theme.colors.header.background};
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 2;

  .logo {
    width: 100px;
  }

  button {
    border: 1px solid ${(props) => props.theme.colors.text};
    background: none;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
  }
`

// const mapStateToProps = (state) => ({
//   preferences: state.preferences.user,
// })
// preferencesVisible: state.preferences.visible
export default connect()(MainHeader)
