// const Practicing = [
//   'Very practising',
//   'Moderately practising',
//   'Somewhat practising',
//   'Not very practising',
// ]

const Practicing = [
  { id: 4, title: 'Very practising' },
  { id: 3, title: 'Moderately practising' },
  { id: 2, title: 'Somewhat practising' },
  { id: 1, title: 'Not very practising' },
]

export default Practicing
