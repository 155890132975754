import { useState } from 'react'
import { withRouter } from 'react-router'
import styled from 'styled-components'

import { PanelHeader } from '../../Styled/Headers'
import { MessagePanel } from '../../Styled/ProfilePanel'
import { PageWrapFullSizeContainer } from '../../Styled/Wrap'
import { useSelector } from 'react-redux'
import { UserSelector } from '../../Redux/UserSelector'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Paragraph } from '../../Styled/Typography'

const Message = (props) => {
  const { roomId, onBack } = props
  //const roomId = props.match.params.id
  const user = useSelector(UserSelector)
  const [title, setTitle] = useState()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const back = () => {
    onBack()
    //props.history.push('/messages')
  }

  const viewProfile = () => {
    alert(11)
  }

  const dots = [
    { title: 'View profile', onClick: viewProfile },
    { title: 'Unmatch', onClick: viewProfile },
    { title: 'Report', onClick: viewProfile },
  ]

  return (
    <PageWrapFullSizeContainer>
      <PanelHeader title={title} back={() => back()} dotsToggle={handleClick} />

      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <Paragraph>View profile</Paragraph>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Paragraph>Unmatch</Paragraph>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Paragraph>Report</Paragraph>
        </MenuItem>
      </StyledMenu>

      <MessagePanel onTitleUpdate={setTitle} user={user} roomId={roomId} />
    </PageWrapFullSizeContainer>
  )
}

export default withRouter(Message)

const StyledMenu = styled(Menu)`
  margin-top: 20px;
  .MuiList-root {
    padding: 0;
    min-width: 180px;
    background: #c4c4c4;
  }
`
