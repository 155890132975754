import { firestore } from './Firebase.utils'
import moment from 'moment'

export const loadProfiles = async (user, lastDoc, preferences) => {
  if (!user) return
  const limit = 20

  if (!user.profile.preferences) {
    user.profile.preferences = {}
  }

  if (preferences) {
    user.profile.preferences = preferences
  }

  const {
    profile: {
      preferences: {
        height,
        practising,
        education,
        ethnicity,
        group,

        languages,

        age,
        distance,
      },
    },
  } = user

  // age = [22, 46]
  // practising = 4
  // languages = ['Arabic']
  // group = 'Sunni'
  // ethnicity = 'Pakistani'
  // education = 5
  // height = [1,10]

  const ownerId = user.uid
  const currentUserRef = firestore.doc(`users/${ownerId}`)

  var promise = new Promise(function (resolve, reject) {
    let userRef = firestore.collection(`users/`)
    let tags = []

    const gender = user.profile.gender == 'male' ? 'female' : 'male'
    userRef = userRef
      .where('gender', '==', gender)
      .where('status', '==', 'active')
    // .where('dob', '==', '402347007')
    // .where('ethnicity', '==', 'English')
    // .where('group', '==', 'Sunni')
    // .where('role', '==', 'demo')

    if (height) {
      //tags = updateTags(height, tags, 'height')
      //userRef = userRef.orderBy('height').startAt(height[0]).endAt(height[1])
      // console.log('111111111', getNumbersBetween(height[0], height[1]))
      // userRef = userRef.where(
      //   'height',
      //   'in',
      //   getNumbersBetween(height[0], height[1])
      // )
      userRef = userRef
        .orderBy('height')
        .where('height', '>', height[0])
        .where('height', '<', height[1])
    }

    if (practising) {
      const abovePractising = getAboveNumbers(practising * 1, 4)
      userRef = userRef.where('practising', '==', practising * 1)
    }

    if (education) {
      const aboveEducation = getAboveNumbers(education * 1, 7)
      userRef = userRef.where('education', '==', education * 1)
    }

    if (age) {
      userRef = userRef
        .orderBy('dob')
        .where('dob', '>=', Math.abs(moment().subtract(age[1], 'years').unix()))
        .where('dob', '<=', Math.abs(moment().subtract(age[0], 'years').unix()))
    }

    if (group) {
      userRef = userRef.where('group', '==', group)
    }

    if (ethnicity) {
      // userRef = userRef.where('ethnicity', 'in', ethnicity)
      userRef = userRef.where('ethnicity', '==', ethnicity)
      // ethnicity.map((item) => {
      //   tags.push(`eth_${item}`)
      // })
    }
    if (languages) {
      userRef = userRef.where('languages', 'array-contains', languages)
      // if (!ethnicity || ethnicity.length < 1) {
      //   languages.map((item) => {
      //     tags.push(`${item}`)
      //   })
      // } else {
      //   const newTags = []
      //   tags.map((tag, index) => {
      //     languages.map((lang, index) => {
      //       newTags.push(`${tag}_${lang}`)
      //     })
      //   })
      //   tags = newTags
      // }
      // tags = searchTags(languages, tags)
    }

    if (tags && tags.length > 0) {
      userRef = userRef.where('tags', 'array-contains-any', tags)
    }

    // const citiesRef = firestore.collection(`users/`)
    // const q1 = query(
    //   citiesRef,
    //   where('group', '==', 'Sunni'),
    //   where('height', '>', 5)
    // ).get()
    // console.log('q1q1', q1)

    userRef = userRef
      // .where('created', '!=', null)
      .orderBy('created')
      .startAfter(lastDoc || 0)
      .limit(limit)
      .get()
      .then((querySnapshot) => {
        const data = []
        querySnapshot.forEach((documentSnapshot) => {
          const profile = documentSnapshot.data()
          profile.userId = documentSnapshot.id

          try {
            profile.likedQuery = firestore
              .collection(`likes/${documentSnapshot.id}/received`)
              .where('userId', '==', currentUserRef)
            // .get()
            // .then((q1) => {
            //   profile.liked = q1.size > 0
            // })
          } catch (e) {}
          profile.snapshot = documentSnapshot
          data.push(profile)
        })
        resolve(data)
      })
  })

  return promise
}

const getNumbersBetween = (min, max) => {
  const heights = []
  for (let i = min, j = max; i < j; i += 0.1) {
    heights.push(i.toFixed(1) * 1)
  }

  return heights
}

const getAboveNumbers = (min, max) => {
  const x = Array.apply(null, { length: max + 1 })
    .map(Number.call, Number)
    .slice(min)
  return x
}

const searchTags = (items, tags = [], key) => {
  // if (key == 'height') {
  //   items = sequence(items[0], items[1])
  // }
  const newTags = []
  items.map((item) => {
    const current = (key ? `${key}_` : '') + item
    newTags.push(current)
    // if (tags.length > 0 && key != 'eth') {
    tags.map((tag) => {
      newTags.push(`${tag}_${current}`)
    })
    // }
  })
  console.log('newTags', newTags)
  return newTags
}

const sequence = (start, end) => {
  const obj = []
  let i = start
  while (i <= end) {
    obj.push(i.toFixed(1) * 1)
    i = i + 0.1
  }

  return obj
}

export const loadProfile = async (id) => {
  var promise = new Promise(function (resolve, reject) {
    const userRef = firestore
      .doc(`users/${id}`)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.data()
        if (data) {
          data.userId = id
          resolve(data)
        }
      })
  })

  return promise
}
