// import './loader.scss'
import styled from 'styled-components'

const Loader = (props) => {
  if (props.visible) {
    return (
      <PageLoader>
        <RingLoader />
        <Background />
      </PageLoader>
    )
  }

  return null
}

const ComponentLoader = (props) => {
  if (props.visible) {
    return (
      <ComponentLoaderContainer>
        <RingLoader />
        <Background position={props.position} />
      </ComponentLoaderContainer>
    )
  }

  return null
}

export { Loader, ComponentLoader as default }

const PageLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`
const ComponentLoaderContainer = styled.div``

const RingLoader = styled.div`
  position: absolute;
  display: inline-block;
  z-index: 1;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;

  &:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Background = styled.div`
  position: ${({ position = 'fixed' }) => position};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  transition: opacity 0.3s;
`
