import styled from 'styled-components'

export const MainTemplateContainer = styled.div`
  min-height: 100%;
  height: 100%;
  color: ${(props) => props.theme.colors.text};

  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.fontSize};
  line-height: ${(props) => props.theme.fontLineHeight};
  overflow-y: auto;
  overflow-x: hidden;

  time {
    color: #bbbbbb;
    font-size: 12px;
  }

  .link {
    text-decoration: underline;
  }

  strong {
    font-weight: bold;
  }

  .text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

const MainTemplate = (props) => {
  return (
    <MainTemplateContainer className={props.className}>
      {props.children}
    </MainTemplateContainer>
  )
}

export default MainTemplate
