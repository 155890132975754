import { firestore } from './Firebase.utils'
import moment from 'moment'

export const adminLoadProfiles = async (user) => {
  if (!user) return

  const {
    profile: { role },
  } = user

  var promise = new Promise(function (resolve, reject) {
    let userRef = firestore.collection(`users/`)
    let tags = []

    userRef = userRef
      .limit(100)
      .get()
      .then((querySnapshot) => {
        const data = []
        querySnapshot.forEach((documentSnapshot) => {
          const profile = documentSnapshot.data()
          profile.userId = documentSnapshot.id

          data.push(profile)
        })
        resolve(data)
      })
  })

  return promise
}

export const deleteDummyUsers = () => {
  var promise = new Promise(function (resolve, reject) {
    const useRef = firestore
      .collection(`users/`)
      .where('role', '==', 'demo')

      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((val) => {
          val.ref.delete()
        })

        resolve(true)
      })
  })

  return promise
}
