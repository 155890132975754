import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import logo from '../assets/svg/logo.svg'
import { Button } from '../Styled/Button'
import { Image } from '../Styled/Icons/Image'
import { Heading, Paragraph } from '../Styled/Typography'

const Landing = () => {
  return (
    <LandingContainer data-test-id={'LandingContainer'}>
      <PageWrap>
        <div className="flex-container">
          <div className="flex-top">
            <Image src={logo} title={'Salam Match'} alt={'Salam Match'} />
            <Heading size={17} type={'h2'}>
              Keeping things simple.
            </Heading>
          </div>

          <div className="flex-bottom">
            <NavLink to={'/sign-in'}>
              <Button disabled={false}>Continue with phone number</Button>
            </NavLink>

            <Paragraph size={11} color={'#888888'} isInnerHTML={true}>
              By signing up to Salam Match, you agree to our{' '}
              <a href="#" className="link">
                Terms of Service
              </a>
              . Learn how we process your data in our{' '}
              <a href="#" className="link">
                Privacy Policy
              </a>
              .
            </Paragraph>
          </div>
        </div>
      </PageWrap>
    </LandingContainer>
  )
}

export default Landing

const PageWrap = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 20px;
`

const LandingContainer = styled.div`
  height: 100vh;
  background: ${(props) => props.theme.colors.landingBackground};

  .flex-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    h2 {
      color: #777777;
      margin-top: 10px;
    }

    .flex-top {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .flex-bottom {
      padding-bottom: 100px;
    }
  }
`
