import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router'

import { Heading, Paragraph } from '../Typography'
import { getTileInfo } from '../../Services/UserServices'
import { loadProfile } from '../../Firebase/Firebase.LoadProfiles'
import { Image } from '../Icons/Image'
import Modal from '../../Pages/Modal/Modal'
import Profile from '../../Pages/Profile'

interface LikePanelProps {
  dataItem?: []
  className?: string
  fullSize?: boolean
  history?: any
}

const LikePanel = (props: LikePanelProps) => {
  const [profile, setProfile] = useState()
  const { dataItem, className = '', fullSize = false } = props
  const [profileVisible, setProfileVisible] = useState(false)
  const [user, setUser] = useState(false)

  useEffect(() => {
    if (dataItem && dataItem['userId']) {
      loadProfile(dataItem['userId'].id).then((profile) => {
        setProfile(profile)
      })
      // getReferenceData(dataItem['userId']).then((profile) => {
      //   setProfile(profile)
      // })
    }
  }, [dataItem])

  if (!dataItem || !profile) {
    return null
  }

  const { image, title, subTitle } = getTileInfo(profile)

  const showUser = () => {
    console.log('dataItem', profile)
    setUser(profile)
    setProfileVisible(true)
    // props.history.push(`/user/${dataItem.userId}`)
  }

  const close = () => {
    setProfileVisible(false)
    setTimeout(() => {
      setUser(false)
    }, 400)
  }

  return (
    <>
      <Modal visible={profileVisible}>
        <Profile profile={user} restrictedView={true} onClose={close} />
      </Modal>

      <LikePanelContainer
        className={className}
        fullSize={fullSize}
        onClick={showUser}
      >
        {image && <Image src={image} />}
        <Details>
          <Heading type={'h2'} size={17}>
            {title}
          </Heading>
          <Paragraph>{subTitle}</Paragraph>
          <Paragraph>{`"${dataItem['message']}"`}</Paragraph>
        </Details>
      </LikePanelContainer>
    </>
  )
}

export default withRouter(LikePanel)

const LikePanelContainer = styled.div<{ fullSize?: boolean }>`
  background: #fff;

  &:first-child {
    grid-column: 1/3;
  }

  ${({ fullSize }) => fullSize == true && `grid-column: 1/3;`}

  img {
    min-height: 200px;
    max-height: 200px;
    object-fit: cover;
    min-width: 100%;
    max-width: 100%;
  }
`
const Details = styled.div`
  padding: 5px 15px 15px 15px;
`
