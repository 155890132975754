import { styled } from '@mui/material/styles'
import Slider from '@mui/material/Slider'
interface SliderProps {
  onChange: any
  minDistance?: number | undefined
}

export const CustomSlider = (props: SliderProps) => {
  const handleChange = (event, value) => {
    if (props.minDistance) {
      value[1] = (value[0] * 1 + props.minDistance).toFixed(1) * 1
      value[1] = Math.min(props?.max, value[1])
    }
    if (props.onChange) props.onChange(value)
  }

  function valuetext(value) {
    return `${value}°C`
  }

  return (
    <div>
      <StyledSlider
        getAriaLabel={() => 'Minimum distance'}
        // getAriaLabel={() => 'Range'}
        valueLabelDisplay="on"
        // getAriaValueText={valuetext}
        // marks={marks}
        {...props}
        onChange={handleChange}
        disableSwap
      />
      {/* <CustomSlider {...props} onChange={handleChange}></CustomSlider> */}
    </div>
  )
}

const StyledSlider = styled(Slider)({
  color: '#1A73E8',
  height: 12,
  '& .MuiSlider-track': {
    border: 'none',
    // height: 12,
    // borderRadius: 8,
  },
  '& .MuiSlider-thumb': {
    height: 30,
    width: 30,
    backgroundColor: '#fff',
    border: '4px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#1A73E8',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
})
