import styled from 'styled-components'

export const IconPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 100%;
  width: 50px;
  height: 50px;
`
