import { useState, useEffect } from 'react'
import { PanelHeader } from '../../Styled/Headers'
import { MessageShortPanel } from '../../Styled/ProfilePanel'
import { MainPagesWrap, PageWrapFullSizeContainer } from '../../Styled/Wrap'
import { withRouter } from 'react-router'
import { Gap } from '../../Styled/Wrap/Gap'
import Modal from '../../Pages/Modal/Modal'
import Message from './Message'
import { Paragraph, H3 } from '../../Styled/Typography'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import firebase, { firestore } from '../../Firebase/Firebase.utils'
import { NotificationMessagesSelector } from '../../Redux/NotificationSelector'
import { useSelector } from 'react-redux'
import MessagePreviewPanel from '../../Styled/Messages/MessagePreviewPanel'

const Messages = (props) => {
  const { user } = props
  const [roomId, setRoomId] = useState()
  const [roomMessages, setRoomMessages] = useState()
  const [visibleMessage, setVisibleMessage] = useState()
  const newMessages = useSelector(NotificationMessagesSelector)

  const query = firestore
    .collection('chatrooms')
    // .orderBy('created', 'desc')
    // .limit(100)
    .where('users', 'array-contains', user.uid)

  const [rooms] = useCollectionData(query, {
    idField: 'uid',
  })

  useEffect(() => {
    setRoomMessages(undefined)
    const arrMessages = {}
    if (newMessages) {
      newMessages.map((messages) => {
        if (messages.roomId) {
          arrMessages[messages.roomId] =
            (arrMessages[messages.roomId] ? arrMessages[messages.roomId] : 0) *
              1 +
            1
        }
      })

      setRoomMessages(arrMessages)
      console.log('arrMessages', arrMessages)
    }

    //   if (user && user.uid) {
    //     loadRooms(user.uid).then((rooms) => {
    //       setRooms(rooms)
    //     })
    //   }
  }, [newMessages])

  // if (!user) return null

  const showMessage = (room) => {
    setRoomId(room.roomId)
    setVisibleMessage(true)
  }

  const hideMessage = (room) => {
    setVisibleMessage(false)
    setTimeout(() => {
      setRoomId(false)
    }, 400)
  }

  const empty = () => {
    if (!rooms || rooms.length === 0) {
      return (
        <MainPagesWrap>
          <H3>No messages yet</H3>
        </MainPagesWrap>
      )
    }
  }

  const getRooms = () => {
    if (!rooms || rooms.length === 0) return null

    return rooms.map((room, index) => {
      return (
        <MessagePreviewPanel
          key={index}
          dataItem={room}
          uid={user.uid}
          onShowMessage={() => showMessage(room)}
          unred={isUnred(room)}
        />
      )
    })
  }

  const isUnred = (room) => {
    return roomMessages[room.roomId] > 0
  }

  return (
    <PageWrapFullSizeContainer>
      <Modal visible={visibleMessage}>
        <Message roomId={roomId} onBack={() => hideMessage()} />
      </Modal>

      <PanelHeader title="Messages" />

      {empty()}
      {getRooms()}

      <Gap gap={80} />
    </PageWrapFullSizeContainer>
  )
}

export default withRouter(Messages)
