import { useEffect, useState } from 'react'
import styled from 'styled-components'

export const Image = (props) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 100)
  }, [props.src])

  return <ImageContainer {...props} loaded={loaded} />
}
export const ImageContainer = styled.img<{ loaded: boolean }>`
  transition: opacity 1s ease-in-out;
  opacity: ${({ loaded }) => (loaded == true ? 1 : 0)};
`
