import { firestore, auth } from './Firebase.utils'
import moment from 'moment'
import { collection, addDoc } from 'firebase/firestore'
import firebase from 'firebase/compat/app'

export const loadRooms = async (uid, limit = 20) => {
  var promise = new Promise(function (resolve, reject) {
    const userRef = firestore
      .collection(`chatrooms`)
      .limit(limit)
      .get()
      .then((querySnapshot) => {
        const data = []
        querySnapshot.forEach((documentSnapshot) => {
          const rooms = documentSnapshot.data()
          firestore
            .collection(`chatrooms/${documentSnapshot.id}/messages/`)
            .limit(1)
            .get()
            .then((messages) => {
              rooms.messages = []
              messages.forEach((message) => {
                rooms.messages.push(message.data())
              })
            })
          rooms.roomId = documentSnapshot.id
          data.push(rooms)
        })

        resolve(data)
      })
  })

  return promise
}

export const updateRoom = async (roomId, options) => {
  if (!roomId) return

  const chatRoomRef = firestore.doc(`chatrooms/${roomId}`)
  const snapShot = await chatRoomRef.get()
  if (!snapShot.exists) return

  try {
    chatRoomRef.update(options)
  } catch (error) {
    console.log('11111', error.message)
  }

  return chatRoomRef
}
