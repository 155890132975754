import { firestore, auth } from './Firebase.utils'
import moment from 'moment'
import { collection, addDoc } from 'firebase/firestore'
import firebase from 'firebase/compat/app'

export const createNotification = async (
  userId,
  msg,
  type = 'message',
  options
) => {
  const notificationRef = firestore.collection(`notification/`)
  const created = firebase.firestore.FieldValue.serverTimestamp()
  const sendData = {
    created: created,
    msg: msg,
    userId: userId,
    status: 'active',
    type: type,
  }
  if (options?.roomId) {
    sendData.roomId = options?.roomId
  }

  await notificationRef.add(sendData)
}
