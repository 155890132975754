import { useEffect, useState } from 'react'
import { MainHeader } from '../Styled/Headers'
import { ProfilePanel } from '../Styled/ProfilePanel'
import { MainPagesWrap, PageWrapFullSizeContainer } from '../Styled/Wrap'
import { loadProfiles } from '../Firebase/Firebase.LoadProfiles'
import { Gap } from '../Styled/Wrap/Gap'
import { useSelector } from 'react-redux'
import { UserSelector } from '../Redux/UserSelector'
import { PreferencesSelector } from '../Redux/PreferencesSelector'
import { Paragraph, H3 } from '../Styled/Typography'
import InfiniteScroll from 'react-infinite-scroll-component'

const Home = (props) => {
  const [profiles, setProfiles] = useState()
  const [lastItem, setLastItem] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const user = useSelector(UserSelector)
  const preferences = useSelector(PreferencesSelector)

  useEffect(() => {
    if (user) {
      fetchMoreData(true)
    }
  }, [user, preferences])

  useEffect(() => {
    if (profiles) {
      const poped = profiles.slice(-1).pop()
      if (poped) {
        setLastItem(poped.snapshot)
      }
    }
  }, [profiles])

  const empty = () => {
    if (!profiles || profiles.length === 0) {
      return (
        <MainPagesWrap>
          <H3>No profiles found, try to change search creteria</H3>
        </MainPagesWrap>
      )
    }
  }

  const fetchMoreData = (reset = false) => {
    setLoading(true)
    loadProfiles(user, reset === true ? null : lastItem, preferences).then(
      (data) => {
        setLoading(false)
        const tmp = profiles && reset !== true ? profiles.concat(data) : data
        setProfiles(tmp)
      }
    )
  }

  const loader = () => {
    return (
      <div className="loader" key={0}>
        Loading ...
      </div>
    )
  }

  if (!user) return null

  return (
    <PageWrapFullSizeContainer>
      <MainHeader />

      {empty()}

      <InfiniteScroll
        dataLength={profiles ? profiles.length : 0}
        next={() => fetchMoreData(false)}
        hasMore={true}
        pageStart={currentPage - 1}
        loader={loader}
        // height={700}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        {profiles &&
          profiles.map((profile, index) => {
            return <ProfilePanel key={index} dataItem={profile} />
          })}
      </InfiniteScroll>

      <Gap gap={60} />
    </PageWrapFullSizeContainer>
  )
}

// const mapStateToProps = (state) => ({
//   user: state.auth.user,
// })
export default Home
