import { UserType } from '../Types/User'

let INITIAL_STATE = {
  createUser: {},
  preferences: {
    age: [18, 54],
    height: [4.8, 5.6],
    distance: false,
    ethnicity: [],
    practising: false,
    languages: [],
    education: false,
    group: false,
  },
}

const CreateUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'createUser':
      let payload = state.createUser
      if (action.node) {
        payload[action.node] = action.payload
      }
      return {
        ...state,
        createUser: { ...payload },
      }
    case 'createUserPreferences':
      let preferences = state.preferences
      if (action.node) {
        preferences[action.node] = action.payload
      }
      return {
        ...state,
        preferences: { ...preferences },
      }
    default:
      return state
  }
}

export default CreateUserReducer
