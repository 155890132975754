import { withRouter } from 'react-router'
import Profile from './Profile'
import { useSelector } from 'react-redux'
import { UserSelector } from '../Redux/UserSelector'

const ProfileMy = (props) => {
  const user = useSelector(UserSelector)
  console.log('user13', user)
  return <Profile profile={user.profile} restrictedView={false} />
}

export default withRouter(ProfileMy)
