import { useMemo, useState } from 'react'
import { storage } from '../Firebase/Firebase.utils'

type UseUploadImagesProps = { userImages?: [] }

export const useUploadImages = (props: UseUploadImagesProps) => {
  let { userImages = [] } = props
  if (userImages) {
    const tmp: any = []
    userImages.map((userImage) => {
      tmp.push({ webUrl: userImage })
    })
    userImages = tmp
  }
  const [img, setImg] = useState(userImages)
  const [urls, setUrls] = useState([])
  const [progress, setProgress] = useState(0)

  const getImages = () => {
    return [...img]
  }

  const remove = (file) => {
    const tmp = img.filter((im) => {
      return im.webUrl != file.webUrl
    })

    setImg(tmp)
  }

  const upload = (images) => {
    const promises = []
    setImg(images)
    // setImg((img) => [...img, images])

    images.map((image) => {
      const uploadTask = storage
        .ref(`images/${image.name}`)
        .put(image, { cacheControl: 'max-age=131536000' })

      promises.push(uploadTask)
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          )
          setProgress(progress)
        },
        (error) => {
          console.log(error)
        },
        async () =>
          await storage
            .ref('images')
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              image.webUrl = url
              image.isUploaded = true
              setUrls((urls) => [...urls, url])
            })
      )
    })

    const promise = new Promise(function (resolve, reject) {
      Promise.all(promises)
        .then(() => {
          // alert('All done!')
          setImg(images)
          // setImg((img) => [...img, images])
          resolve(images)
        })
        .catch((err) => {
          console.log('err')
        })
    })

    return promise
  }

  return useMemo(() => {
    return { upload, getImages, remove }
  }, [upload, getImages, remove])
}
