const items = [
  { name: 'Afrikaans', code: 'af' },
  { name: 'Albanian', code: 'sq' },
  // { name: 'Amharic - አማርኛ', code: 'am' },
  { name: 'Arabic', code: 'ar' },
  // { name: 'Aragonese', code: 'an' },
  // { name: 'Armenian', code: 'hy' },
  // { name: 'Asturian', code: 'ast' },
  // { name: 'Azerbaijani', code: 'az' },
  // { name: 'Basque', code: 'eu' },
  // { name: 'Belarusian', code: 'be' },
  { name: 'Bengali', code: 'bn' },
  { name: 'Bosnian', code: 'bs' },
  // { name: 'Breton - brezhoneg', code: 'br' },
  { name: 'Bulgarian', code: 'bg' },
  // { name: 'Catalan', code: 'ca' },
  // { name: 'Central Kurdish', code: 'ckb' },
  { name: 'Chinese', code: 'zh' },
  // { name: 'Chinese (Hong Kong) - 中文（香港）', code: 'zh-HK' },
  // { name: 'Chinese (Simplified) - 中文（简体）', code: 'zh-CN' },
  // { name: 'Chinese (Traditional) - 中文（繁體）', code: 'zh-TW' },
  { name: 'Corsican', code: 'co' },
  { name: 'Croatian', code: 'hr' },
  { name: 'Czech', code: 'cs' },
  { name: 'Danish', code: 'da' },
  { name: 'Dutch', code: 'nl' },
  { name: 'English', code: 'en' },
  // { name: 'English (Australia)', code: 'en-AU' },
  // { name: 'English (Canada)', code: 'en-CA' },
  // { name: 'English (India)', code: 'en-IN' },
  // { name: 'English (New Zealand)', code: 'en-NZ' },
  // { name: 'English (South Africa)', code: 'en-ZA' },
  // { name: 'English (United Kingdom)', code: 'en-GB' },
  // { name: 'English (United States)', code: 'en-US' },
  // { name: 'Esperanto - esperanto', code: 'eo' },
  { name: 'Estonian', code: 'et' },
  // { name: 'Faroese - føroyskt', code: 'fo' },
  { name: 'Filipino', code: 'fil' },
  { name: 'Finnish', code: 'fi' },
  { name: 'French', code: 'fr' },
  // { name: 'French (Canada) - français (Canada)', code: 'fr-CA' },
  // { name: 'French (France) - français (France)', code: 'fr-FR' },
  // { name: 'French (Switzerland) - français (Suisse)', code: 'fr-CH' },
  // { name: 'Galician - galego', code: 'gl' },
  { name: 'Georgian', code: 'ka' },
  { name: 'German', code: 'de' },
  // { name: 'German (Austria) - Deutsch (Österreich)', code: 'de-AT' },
  // { name: 'German (Germany) - Deutsch (Deutschland)', code: 'de-DE' },
  // { name: 'German (Liechtenstein) - Deutsch (Liechtenstein)', code: 'de-LI' },
  // { name: 'German (Switzerland) - Deutsch (Schweiz)', code: 'de-CH' },
  { name: 'Greek', code: 'el' },
  // { name: 'Guarani', code: 'gn' },
  { name: 'Gujarati', code: 'gu' },
  // { name: 'Hausa', code: 'ha' },
  // { name: 'Hawaiian - ʻŌlelo Hawaiʻi', code: 'haw' },
  { name: 'Hebrew', code: 'he' },
  { name: 'Hindi', code: 'hi' },
  { name: 'Hungarian', code: 'hu' },
  // { name: 'Icelandic - íslenska', code: 'is' },
  { name: 'Indonesian', code: 'id' },
  // { name: 'Interlingua', code: 'ia' },
  { name: 'Irish', code: 'ga' },
  { name: 'Italian', code: 'it' },
  // { name: 'Italian (Italy) - italiano (Italia)', code: 'it-IT' },
  // { name: 'Italian (Switzerland) - italiano (Svizzera)', code: 'it-CH' },
  { name: 'Japanese', code: 'ja' },
  // { name: 'Kannada - ಕನ್ನಡ', code: 'kn' },
  { name: 'Kazakh', code: 'kk' },
  // { name: 'Khmer - ខ្មែរ', code: 'km' },
  { name: 'Korean', code: 'ko' },
  { name: 'Kurdish', code: 'ku' },
  { name: 'Kyrgyz', code: 'ky' },
  { name: 'Lao', code: 'lo' },
  { name: 'Latin', code: 'la' },
  { name: 'Latvian', code: 'lv' },
  // { name: 'Lingala - lingála', code: 'ln' },
  // { name: 'Lithuanian - lietuvių', code: 'lt' },
  // { name: 'Macedonian - македонски', code: 'mk' },
  { name: 'Malay', code: 'ms' },
  // { name: 'Malayalam - മലയാളം', code: 'ml' },
  // { name: 'Maltese - Malti', code: 'mt' },
  // { name: 'Marathi - मराठी', code: 'mr' },
  { name: 'Mongolian', code: 'mn' },
  { name: 'Nepali', code: 'ne' },
  { name: 'Norwegian', code: 'no' },
  // { name: 'Norwegian Bokmål - norsk bokmål', code: 'nb' },
  // { name: 'Norwegian Nynorsk - nynorsk', code: 'nn' },
  // { name: 'Occitan', code: 'oc' },
  // { name: 'Oriya - ଓଡ଼ିଆ', code: 'or' },
  // { name: 'Oromo - Oromoo', code: 'om' },
  { name: 'Pashto', code: 'ps' },
  { name: 'Persian', code: 'fa' },
  { name: 'Polish', code: 'pl' },
  { name: 'Portuguese', code: 'pt' },
  // { name: 'Portuguese (Brazil) - português (Brasil)', code: 'pt-BR' },
  // { name: 'Portuguese (Portugal) - português (Portugal)', code: 'pt-PT' },
  { name: 'Punjabi', code: 'pa' },
  // { name: 'Quechua', code: 'qu' },
  { name: 'Romanian', code: 'ro' },
  // { name: 'Romanian (Moldova) - română (Moldova)', code: 'mo' },
  // { name: 'Romansh - rumantsch', code: 'rm' },
  { name: 'Russian', code: 'ru' },
  { name: 'Scottish Gaelic', code: 'gd' },
  { name: 'Serbian', code: 'sr' },
  // { name: 'Serbo - Croatian', code: 'sh' },
  // { name: 'Shona - chiShona', code: 'sn' },
  // { name: 'Sindhi', code: 'sd' },
  // { name: 'Sinhala - සිංහල', code: 'si' },
  // { name: 'Slovak - slovenčina', code: 'sk' },
  // { name: 'Slovenian - slovenščina', code: 'sl' },
  { name: 'Somali', code: 'so' },
  // { name: 'Southern Sotho', code: 'st' },
  { name: 'Spanish', code: 'es' },
  // { name: 'Spanish (Argentina) - español (Argentina)', code: 'es-AR' },
  // { name: 'Spanish (Latin America) - español (Latinoamérica)', code: 'es-419' },
  // { name: 'Spanish (Mexico) - español (México)', code: 'es-MX' },
  // { name: 'Spanish (Spain) - español (España)', code: 'es-ES' },
  // { name: 'Spanish (United States) - español (Estados Unidos)', code: 'es-US' },
  { name: 'Sundanese', code: 'su' },
  // { name: 'Swahili - Kiswahili', code: 'sw' },
  { name: 'Swedish', code: 'sv' },
  { name: 'Tajik', code: 'tg' },
  // { name: 'Tamil - தமிழ்', code: 'ta' },
  { name: 'Tatar', code: 'tt' },
  // { name: 'Telugu - తెలుగు', code: 'te' },
  { name: 'Thai', code: 'th' },
  // { name: 'Tigrinya - ትግርኛ', code: 'ti' },
  // { name: 'Tongan - lea fakatonga', code: 'to' },
  { name: 'Turkish', code: 'tr' },
  { name: 'Turkmen', code: 'tk' },
  // { name: 'Twi', code: 'tw' },
  { name: 'Ukrainian', code: 'uk' },
  { name: 'Urdu', code: 'ur' },
  { name: 'Uyghur', code: 'ug' },
  { name: 'Uzbek', code: 'uz' },
  // { name: 'Vietnamese', code: 'vi' },
  // { name: 'Walloon - wa', code: 'wa' },
  { name: 'Welsh', code: 'cy' },
  // { name: 'Western Frisian', code: 'fy' },
  // { name: 'Xhosa', code: 'xh' },
  // { name: 'Yiddish', code: 'yi' },
  // { name: 'Yoruba - Èdè Yorùbá', code: 'yo' },
  // { name: 'Zulu - isiZulu', code: 'zu' },
]

const Languages = items.map((item: { name: string; code: string }) => {
  return item.name
  // return { label: item.name, value: item.code }
})

export default Languages
