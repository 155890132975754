import { useState } from 'react'
import { withRouter } from 'react-router'
import { Carousel } from 'react-responsive-carousel'

import SignupPhone from './SignInPhone'
import SignupPin from './SignInPin'
import { PageWrap } from '../../Styled/Wrap'
import { Heading } from '../../Styled/Typography'
import { useSelector } from 'react-redux'
import { UserSelector } from '../../Redux/UserSelector'

const SignIn = (props) => {
  const user = useSelector(UserSelector)

  const [currentSlide, setCurrentSlide] = useState(0)
  const [pinEvent, setPinEvent] = useState()
  const [phone, setPhone] = useState()

  const next = (direction) => {
    if (direction == undefined) {
      direction = 1
    }
    setCurrentSlide(currentSlide + direction)
  }

  const goToSignUp = () => {
    if (user && user.profile && user.profile.status) {
      return props.history.push('/home')
    }

    return props.history.push('/sign-up')
  }

  const scrollUp = () => {
    window.scrollTo(0, 0)
  }

  return (
    <Carousel
      selectedItem={currentSlide}
      showArrows={false}
      showThumbs={false}
      autoPlay={false}
      centerMode={false}
      dynamicHeight={false}
      infiniteLoop={false}
      autoFocus={false}
      showIndicators={false}
      swipeable={false}
      showStatus={false}
      onChange={scrollUp}
    >
      <SignupPhone
        next={next}
        onPinEvent={setPinEvent}
        phone={phone}
        onSetPhone={setPhone}
      />
      <SignupPin
        back={() => next(-1)}
        next={goToSignUp}
        pinEvent={pinEvent}
        phone={phone}
      />
    </Carousel>
  )
}

export default withRouter(SignIn)

export const SignInTemplate = (props) => {
  const { children, title } = props
  return (
    <PageWrap className="wrap-secondary">
      <Heading size={30} type={'h1'} bottom={10}>
        {title}
      </Heading>

      {children}
    </PageWrap>
  )
}
