import moment from 'moment'
import Education from '../Data/Education'
import EthnicGroups from '../Data/EthnicGroups'
import Group from '../Data/Group'
import Languages from '../Data/Languages'
import Practicing from '../Data/Practicing'

export const getAgo = (date) => {
  if (!date) return
  // const birth = moment.unix(date).format('DD/MM/YYYY')
  // var years = moment().diff(birth, 'years', false)
  return `${moment.unix(date).fromNow(true)} ago`
}

export const getAge = (dob) => {
  if (!dob) return
  // const birth = moment.unix(dob).format('DD/MM/YYYY')
  var years = moment().diff(moment.unix(dob), 'years', false)
  return years
}

export const getHeight = (height) => {
  if (!height) return
  const heights = height.toString().split('.')
  return `${heights[0]}'${heights[1] ? heights[1] : 0}"`
}

export const getEducation = (id) => {
  if (!id) return
  const items = Education
  return getById(items, id)
}

export const getSliderPhotos = (photos) => {
  if (!photos) return
  let items = []
  photos.map((photo) => {
    items.push({ url: photo })
  })

  return items
}

export const getLanguages = (languages) => {
  if (!languages) return
  let text = languages.join(', ')
  const and = 'and'
  text = text.replace(/,([^,]*)$/, ` ${and}$1`)
  return text
}

export const getPractising = (level) => {
  if (!level) return
  const items = Practicing
  return getById(items, level)
}

const getById = (items, id) => {
  const filtered = items.filter((item) => {
    return item.id?.toString() === id?.toString()
  })
  if (filtered && filtered[0]) {
    return filtered[0].title
  }

  return id
}

export const getProfile = (type, profile) => {
  let text = ''

  if (!profile) return

  const {
    name,
    dob,
    height,
    photos,
    occupation,
    ethnicity,
    languages,
    education,
    group,
    practising,
    aboutMe,
    aboutFamily,
    hobby,
    looking,
    status,
    role,
  } = profile

  switch (type) {
    case 'title':
      text = `${name}, ${getAge(dob)}, London`
      break
    case 'height':
      text = getHeight(height)
      break

    case 'languages':
      text = getLanguages(languages)
      break

    case 'ethnicity':
      text = ethnicity
      break
    case 'education':
      text = getEducation(education)
      break
    case 'group':
      text = group
      break
    case 'practising':
      text = practising
      break

    case 'aboutMe':
      text = aboutMe
      break
    case 'aboutFamily':
      text = aboutFamily
      break
    case 'hobby':
      text = hobby
      break
    case 'looking':
      text = looking
      break

    case 'occupation':
      text = occupation
      break
    case 'status':
      text = status
      break
    case 'role':
      text = role ? role : 'User'
      break
  }

  // if (!text) {
  // text = 'Not set'
  // }
  return text
}

export const getPreferenceOptions = (id, isProfile = false) => {
  switch (id) {
    case 'age':
      return {
        title: 'Select age range',
        step: 1,
        min: 18,
        max: 52,
        defaultValue: [18, 52],
      }

    case 'height':
      return {
        title: 'Select height range',
        step: 0.1,
        min: 4,
        max: 7,
        minDistance: 0.8,
        defaultValue: [4.8, 5.6],
      }

    case 'distance':
      return {
        title: 'Maximum distance',
        step: 1,
        min: 0,
        max: 1000,
        defaultValue: 30,
      }

    case 'education':
      return {
        items: Education,
      }

    case 'group':
      return {
        items: Group,
      }

    case 'languages':
      return {
        items: Languages,
        multiple: true,
      }

    case 'practising':
      return {
        items: Practicing,
      }
    case 'ethnicity':
      return {
        items: EthnicGroups,
        // multiple: isProfile ? false : true,
        multiple: false,
      }

    case 'name':
      return {
        title: 'Enter your name',
      }

    case 'occupation':
      return {
        title: 'Enter your occupation',
      }

    case 'dob':
      return {
        title: 'Enter your date of birth',
      }

    case 'location':
      return {
        title: 'Enter your location',
      }
  }
}

export const getFormatted = (value, id) => {
  if (!value) return 'Not set'

  switch (id) {
    case 'dob':
      return moment.unix(value).format('DD/MM/YYYY')

    case 'age':
      return value

    case 'height':
      return getHeight(value)

    case 'distance':
      return value + ' miles'
  }

  return value
}

export const getListItems = (isProfile = false) => {
  let items = [
    'age',
    // 'height',
    // 'distance',
    'ethnicity',
    'languages',
    'education',
    'group',
    'practising',
  ]

  if (isProfile) {
    items = [
      'name',
      'occupation',
      'dob',
      // 'location',
      'height',
      'ethnicity',
      'languages',
      'education',
      'group',
      'practising',
    ]
  }

  return items
}

export const getTileInfo = (profile) => {
  const photos = profile?.photos
  const image = photos ? photos[0] : undefined
  const title = getProfile('title', profile)
  const subTitle = getProfile('occupation', profile)

  return { image, title, subTitle }
}
